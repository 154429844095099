import React, { Component } from "react";
import {
  AddinClient,
  AddinTileSummaryStyle,
} from "@blackbaud/sky-addin-client";
import "./donorsearchdata.scss";
import "../../main.scss";
import { apiCall, getUserFriendlyErrorMessage } from "../../utils/restclient";
import moment from "moment";
import close from "../../components/images/close_tile.png";
import { parseToken } from "../../utils/parsetoken";
import PopUpView from "../../components/lib/pop-up-view/popupview";
import NXTWait from "../../components/lib/nxt-wait/nxtwait";
import Footer from "../../components/lib/footer/footer";
import { debounce } from "lodash";
import {
  checkLocalStorage,
  getStorageDisabledView,
} from "../../utils/browserhelper";
import { checkVersion } from "../../utils/buildinfo";

class DonorSearchDataTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      envId: "",
      nameid: "",
      lastUpdatedDate: "",
      showPopUp: false,
      loggedIn: false,
      userIdToken: "",
      JwtAccessToken: "",
      BlackbaudSubscriptionKey: "",
      BlackbaudApiUrl: "",
      BlackbaudAccessToken: "",
      recordId: "",
      preferredAddressId: "",
      preferredAddress: "",
      preferredAddressType: "",
      showLastUpdate: false,
      donorScore: null,
      showError: false,
      showDataInfoWarning: false,
      showLocation: false,
      showIndicator: false,
      donorScoreCategories: null,
      blockOut: false,
      savedCategories: [],
      profileLink: "",
      errorMessage: "",
      showPending: false,
      checkedUser: false,
      commandKey: null,
      firstPopUp: false,
      lastSyncErrorMessage: "",
      primaryAddressId: "",
      showLimitView: false,
      limitError: "",
      willDivertToLimitView: true,
      storageEnabled: true,
      loginErrorMessage: null,
      showBatchMsg: false,
      permissionAllowed: true,
    };
    this.client = new AddinClient({
      callbacks: {
        init: (args) => {
          this.setState({
            envId: args.envId,
            recordId: args.context.recordId,
            context: JSON.stringify(args.context, undefined, 2),
          });
          if (checkLocalStorage()) {
            document.addEventListener("visibilitychange", function () {
              if (document.hidden) {
              } else {
                window.localStorage.setItem("recordId", args.context.recordId);
              }
            });
            window.localStorage.setItem("recordId", args.context.recordId);

            this.currentRecordId = args.context.recordId;
            let nameid = this._getStorageItem("nameid");
            let envId = this._getStorageItem("envId");
            this._checkCurrentUser(nameid, envId, null, true);

            args.ready({
              showUI: true,
              title: `DonorSearch constituent data`,
              tileConfig: {
                removeInset: true,
                summaryStyle: AddinTileSummaryStyle.Text,
                summaryText: "",
                showHelp: false,
                showSettings: false,
              },
            });
          } else {
            this.setState({ storageEnabled: false });
            args.ready({
              showUI: true,
              title: `DonorSearch constituent data`,
              tileConfig: {
                removeInset: true,
                summaryStyle: AddinTileSummaryStyle.Text,
                summaryText: "",
                showHelp: false,
                showSettings: false,
              },
            });
          }
        },
      },
    });
  }

  _autoLogin(callback = null) {
    let authApi = "v1/auth/userIdentityToken";
    apiCall(
      authApi,
      "POST",
      null,
      JSON.stringify({ UserIdentityToken: this.state.userIdToken })
    )
      .then((response) => {
        this.setState({
          JwtAccessToken: response.JwtAccessToken,
          loggedIn: true,
          showError: false,
          showIndicator: false,
          blockOut: false,
          permissionAllowed: response.IsSingleUpdateAllowed,
        });
        window.localStorage.setItem(
          `JwtAccessToken`,
          JSON.stringify({
            data: response.JwtAccessToken,
            expires: moment()
              .add(10, "minutes")
              .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          })
        );
        if (!response.IsSingleUpdateAllowed) {
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(403),
          });
        }
        if(callback == "Cancel")
        {
          //cancel the TokenNotValid error callback only.
          this.setState({checkedUser: false});
          callback = null;
        }
        this._checkGetBlackbaudTokens(null, null, callback);
      })
      .catch((e) => {
        this.setState({ showIndicator: false, blockOut: false });
        if (e.ErrorCode === "TokenNotValid") {
          if (this.state.checkedUser) {
            return
          } else {
            this.setState({ checkedUser: true });
            this._checkCurrentUser(this.state.nameid, this.state.envId,"Cancel", true);
          }
          return;
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  }
  _checkCurrentUser(nameid, envId, callback = null, forceLogin = false) {
    this.setState({ showIndicator: true, blockOut: callback ==null ? true : false  });
    this.client
      .getUserIdentityToken()
      .then((token) => {
        let parsedToken = parseToken(token);
        window.localStorage.setItem(
          `envId`,
          JSON.stringify({
            data: parsedToken["environment/id"],
            expires: moment().add(2, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          })
        );
        window.localStorage.setItem(
          `nameid`,
          JSON.stringify({
            data: parsedToken.nameid,
            expires: moment().add(2, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          })
        );
        this.setState({
          nameid: parsedToken.nameid,
          userIdToken: token,
          envId: parsedToken["environment/id"],
        });
        if (
          parsedToken.nameid === nameid &&
          parsedToken["environment/id"] === envId
        ) {
          let jwt = this._getStorageItem("JwtAccessToken");
          if (jwt && !forceLogin) {
            this.setState({
              JwtAccessToken: jwt,
              loggedIn: true,
              showIndicator: false,
              blockOut: false,
            });
            this._checkGetBlackbaudTokens(parsedToken.nameid, jwt);
            if (!forceLogin) return;
          }
        } else {
          window.localStorage.removeItem("JwtAccessToken");
          window.localStorage.removeItem("BlackbaudAccessToken");
          window.localStorage.removeItem("userIdToken");
        }
        this._autoLogin(callback);
      })
      .catch((e) => {
        this.setState({ showIndicator: false });
        console.log("error getting token");
        this.setState({
          showError: true,
          errorMessage: getUserFriendlyErrorMessage(9),
        });
      });
  }

  _checkGetBlackbaudTokens(nameid, jwt, callback) {
    let recordId = this.state.recordId;
    if (this.state.nameid) {
      nameid = this.state.nameid;
    }
    if (this.state.JwtAccessToken) {
      jwt = this.state.JwtAccessToken;
    }
    this._deleteExpiredItems();
    let commandKey = this._getStorageItem(
      `commandKey_${recordId}_${nameid}_${this.state.envId}`
    );

    let BlackbaudSubscriptionKey = window.localStorage.getItem(
      `BlackbaudSubscriptionKey`
    );
    let BlackbaudApiUrl = window.localStorage.getItem(`BlackbaudApiUrl`);
    let BlackbaudAccessToken =
      window.localStorage.getItem(`BlackbaudAccessToken`);
    let donorScoreCategories = this._getStorageItem(
      `donorScoreCategories_${recordId}_${nameid}_${this.state.envId}`
    );
    let lastUpdatedDate = this._getStorageItem(
      `LastUpdate_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
    );
    let profileLink = this._getStorageItem(
      `profileLink_${recordId}_${nameid}_${this.state.envId}`
    );

    this.setState({
      commandKey,
      showPending: commandKey ? true : false,
      donorScoreCategories,
      BlackbaudSubscriptionKey,
      BlackbaudApiUrl,
      BlackbaudAccessToken,
      lastUpdatedDate,
      showIndicator: true,
      profileLink,
      showError: false,
    });
    if (BlackbaudAccessToken && BlackbaudApiUrl && BlackbaudSubscriptionKey) {
      if (callback) {
        this.setState({ showIndicator: false });
        callback();
        return;
      }
      this._updateDonorCategories(false, true);
      this._getDonor(true);
    } else {
      let authApi = `v1/auth/blackbaud/accessToken`;
      apiCall(authApi, "GET", {
        Authorization: "Bearer " + jwt,
      })
        .then((response) => {
          this.setState({
            BlackbaudSubscriptionKey: response.BlackbaudSubscriptionKey,
            BlackbaudApiUrl: response.BlackbaudApiUrl,
            BlackbaudAccessToken: response.BlackbaudAccessToken,
          });
          window.localStorage.setItem(
            `BlackbaudSubscriptionKey`,
            response.BlackbaudSubscriptionKey
          );
          window.localStorage.setItem(
            `BlackbaudApiUrl`,
            response.BlackbaudApiUrl
          );
          window.localStorage.setItem(
            `BlackbaudAccessToken`,
            response.BlackbaudAccessToken
          );

          if (callback) {
            this.setState({ showIndicator: false });
            callback();
            return;
          }
          this._updateDonorCategories(false, true);
          this._getDonor(false);
        })
        .catch((e) => {
          this.setState({ showIndicator: false });
          if (e.Message === "Unauthorized") {
            if (this.state.checkedUser) {
              this.setState({
                showError: true,
                errorMessage: getUserFriendlyErrorMessage(
                  e.UserFriendlyErrorCode,
                  e.Message
                ),
              });
            } else {
              this.setState({ checkedUser: true });
              this._checkCurrentUser(nameid, this.state.envId);
            }
            return;
          }
          if (e.ErrorCode === "ServerError") {
            if (e.IsLimitError) {
              this.setState({ showLimitView: true, limitError: e.Message });
              return;
            }
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          }
        });
    }
  }
  _getDonor(checkData, recall = false) {
    let getDonorAPI = `v1/donors/${this.state.recordId}`;
    apiCall(getDonorAPI, "GET", {
      Authorization: "Bearer " + this.state.JwtAccessToken,
    })
      .then((donorResponse) => {
        if (
          donorResponse.Address == null ||
          (donorResponse.Address &&
            donorResponse.Address.AddressId !==
              "" + this.state.preferredAddressId)
        ) {
          this._setAddress(donorResponse.Address);
        }

        if (donorResponse.LastSyncDateTimeUtc) {
          window.localStorage.setItem(
            `LastUpdate_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`,
            JSON.stringify({
              data: moment(donorResponse.LastSyncDateTimeUtc)
                .local()
                .format("MM/DD/YYYY"),
              expires: moment()
                .add(30, "days")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            })
          );
          //Setting this quickly to avoid race condition
          this.state.lastUpdatedDate = moment(donorResponse.LastSyncDateTimeUtc)
            .local()
            .format("MM/DD/YYYY");
          this.forceUpdate();
        }
        if (donorResponse.LastSyncUserFriendlyErrorCode) {
          this.setState({
            lastSyncErrorMessage: getUserFriendlyErrorMessage(
              donorResponse.LastSyncUserFriendlyErrorCode
            ),
            showError: true,
          });
        }
        if (donorResponse.IsPending) {
          this.setState({ showPending: true });
          setTimeout(() => {
            if (this.state.willDivertToLimitView) {
              this.setState({ willDivertToLimitView: false });
            }

            this._getDonor(null, true);
          }, 5000);
        } else {
          if (this.state.showPending && checkData && this.state.commandKey) {
            this._checkDataSync(this.state.commandKey, true);
            return;
          }
          if (this.state.commandKey) {
            window.localStorage.removeItem(
              `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
            );
          }
          this.setState({
            showPending: false,
            showPopUp: false,
            commandKey: null,
          });
          if (recall) this._updateDonorCategories(false);
        }
      })
      .catch((e) => {
        this.setState({ showIndicator: false });
        if (e.Message === "Unauthorized") {
          if (this.state.checkedUser) {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          } else {
            this.setState({ checkedUser: true });
            this._checkCurrentUser(this.state.nameid, this.state.envId);
          }
          return;
        }
        if (e.ErrorCode === "DonorNotFound") {
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(e.UserFriendlyErrorCode),
          });
        } else if (e.ErrorCode === "OrganizationConstituent") {
          this.setState({
            showLocation: true,
          });
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  }
  _checkJWT(popUP) {
    let jwt = this._getStorageItem("JwtAccessToken");
    let error = window.localStorage.getItem("loginError");
    if (jwt) {
      this.setState({
        JwtAccessToken: jwt,
        loggedIn: true,
        showIndicator: false,
      });
      if (this._getStorageItem("IsSingleUpdateAllowed") == null) {
        this.setState({ permissionAllowed: false });
      }
      this._checkGetBlackbaudTokens();
      setTimeout(() => {
        if (!popUP.closed) {
          popUP.close();
        }
      }, 500);
    } else if (error) {
      error = JSON.parse(window.localStorage.getItem("loginError")).data;
      this.setState({
        showIndicator: false,
        JwtAccessToken: "",
        loggedIn: false,
        loginErrorMessage:
          error.UserFriendlyErrorCode === 10
            ? getUserFriendlyErrorMessage(error.UserFriendlyErrorCode)
            : null,
      });
      window.localStorage.removeItem("loginError");
      window.localStorage.removeItem("JwtAccessToken");
      setTimeout(() => {
        if (!popUP.closed) {
          popUP.close();
        }
      }, 500);
    } else if (popUP.closed) {
      this.setState({ showIndicator: false });
    } else {
      setTimeout(() => {
        this._checkJWT(popUP);
      }, 1000);
    }
  }
  _deleteExpiredItems() {
    for (var item in window.localStorage) {
      if (
        item !== "BlackbaudSubscriptionKey" &&
        item !== "BlackbaudApiUrl" &&
        item !== "BlackbaudAccessToken" &&
        item !== "userIdToken" &&
        item !== "randid" &&
        item !== "length" &&
        item !== "key" &&
        item !== "getItem" &&
        item !== "setItem" &&
        item !== "removeItem" &&
        item !== "clear" &&
        item !== "recordId"
      ) {
        let type = item.substring(0, item.indexOf("_"));
        if (
          item !==
          `${type}_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
        ) {
          try {
            let data = JSON.parse(window.localStorage[item]);
            if (moment(data.expires).isBefore(moment().local())) {
              window.localStorage.removeItem(item);
            }
          } catch (e) {}
        }
      }
    }
  }
  _saveAddress(address, save = true, showWarning = false) {
    let setAddressAPI = `v1/donors/${this.state.recordId}/address`;
    if (save) {
      apiCall(
        setAddressAPI,
        "PUT",
        {
          Authorization: "Bearer " + this.state.JwtAccessToken,
        },
        JSON.stringify({ AddressId: address.id })
      )
        .then((addressResponse) => {
          this._setAddress(address);
          if (showWarning) {
            this.setState({ showDataInfoWarning: true });
          }
        })
        .catch((e) => {
          if (e.Message === "Unauthorized") {
            window.localStorage.removeItem("JwtAccessToken");
            this.setState({ JwtAccessToken: "" });
            this._checkCurrentUser(this.state.nameid, this.state.envId, () => {
              this._saveAddress(address, save, showWarning);
            });
            return;
          }
          if (e.ErrorCode === "AddressNotFound") {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode
              ),
            });
          } else if (e.ErrorCode === "DonorNotFound") {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode
              ),
            });
          }
          if (e.ErrorCode === "PermissionError") {
            this._autoLogin();
          }
          if (e.ErrorCode === "ServerError") {
            if (e.IsLimitError && this.state.willDivertToLimitView) {
              this.setState({ showLimitView: true, limitError: e.Message });
              return;
            }
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          }
        });
    } else {
      this._setAddress(address);
    }
  }
  _getFormattedAddress(address) {
    let addressString = "";
    if (address === null) {
      return "";
    }
    if (address.id) {
      addressString += address.address_lines;
      // addressString = addressString? addressString + "\n" : addressString;
      addressString += address.city ? " " + address.city + ", " : ", ";
      addressString += address.state + " " + address.postal_code;
      addressString = addressString.replace(/null/g, "").trim();
      addressString = addressString.replace(/undefined/g, "").trim();
      if (addressString[addressString.length - 1] === ",") {
        addressString = addressString.replace(/.$/g, "").trim();
      }
    } else if (address.AddressId) {
      addressString +=
        address.Address1 + (address.Address2 ? " " + address.Address2 : "");
      // addressString = addressString? addressString + "\n" : addressString;
      addressString += address.City ? " " + address.City + ", " : ", ";
      addressString += address.StateCode + " " + address.Zip;
      addressString = addressString.replace(/null/g, "").trim();
      addressString = addressString.replace(/undefined/g, "").trim();
      if (addressString[addressString.length - 1] === ",") {
        addressString = addressString.replace(/.$/g, "").trim();
      }
    }
    return addressString;
  }
  _setAddress(address) {
    let formattedAddress = this._getFormattedAddress(address);
    let type = address.Type ? address.Type : address.type;

    window.localStorage.setItem(
      `preferredAddress_${this.state.recordId}_${this.state.envId}`,
      JSON.stringify({
        data: formattedAddress.replace(/\r\n/g, " ").trim(),
        expires: moment().add(30, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      })
    );
    window.localStorage.setItem(
      `preferredAddressId_${this.state.recordId}_${this.state.envId}`,
      JSON.stringify({
        data: address.id || address.AddressId,
        expires: moment().add(30, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      })
    );
    window.localStorage.setItem(
      `preferredAddressType_${this.state.recordId}_${this.state.envId}`,
      JSON.stringify({
        data: type.replace(" Address", ""),
        expires: moment().add(30, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      })
    );
    this.setState({
      preferredAddressType: type.replace(" Address", ""),
      preferredAddress: formattedAddress.replace(/\r\n/g, " ").trim(),
      preferredAddressId: address.id || address.AddressId,
    });
  }

  _getStorageItem(name, andData = true) {
    try {
      if (andData) {
        if (window.localStorage.getItem(name)) {
          return JSON.parse(window.localStorage.getItem(name)).data;
        } else {
          return null;
        }
      } else return window.localStorage.getItem(name);
    } catch (e) {
      return null;
    }
  }
  _login = () => {
    this.setState({ showError: false });
    let jwt = this._getStorageItem("JwtAccessToken");
    if (jwt) {
      this.setState({ JwtAccessToken: jwt, loggedIn: true, showIndicator: true });
      setTimeout(()=>{
        this._checkGetBlackbaudTokens();
      },300)
    } else {
      this.setState({ showIndicator: true });
      let token = window.localStorage.getItem("userIdToken");
      let authApi = "";

      if (token) {
        this.setState({ userIdToken: token });

        authApi = `v1/auth/blackbaud/url?userIdentityToken=${token}`;
        apiCall(authApi, "GET")
          .then((response) => {
            this.openPopup(response.Url);
          })
          .catch((e) => {
            if (e.ErrorCode === "TokenNotValid") {
              this._getUserTokenLogin();
            } else {
              this.setState({ showIndicator: false });
              if (e.ErrorCode === "ServerError") {
                if (e.IsLimitError && this.state.willDivertToLimitView) {
                  this.setState({ showLimitView: true, limitError: e.Message });
                  return;
                }
                this.setState({
                  showError: true,
                  errorMessage: getUserFriendlyErrorMessage(
                    e.UserFriendlyErrorCode,
                    e.Message
                  ),
                });
              }
            }
          });
      } else {
        this._getUserTokenLogin();
      }
    }
  };
  _getUserTokenLogin(andLogin = true) {
    this.client
      .getUserIdentityToken()
      .then((token) => {
        if (!andLogin) {
          return token;
        }
        this.setState({ userIdToken: token, showIndicator: true });
        window.localStorage.setItem("userIdToken", token);
        let authApi = "v1/auth/userIdentityToken";

        apiCall(
          authApi,
          "POST",
          null,
          JSON.stringify({ UserIdentityToken: token })
        )
          .then((response) => {
            this.setState({
              JwtAccessToken: response.JwtAccessToken,
              loggedIn: true,
              showIndicator: false,
              permissionAllowed: response.IsSingleUpdateAllowed,
            });
            window.localStorage.setItem(
              `JwtAccessToken`,
              JSON.stringify({
                data: response.JwtAccessToken,
                expires: moment()
                  .add(10, "minutes")
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              })
            );
            if (!response.IsSingleUpdateAllowed) {
              this.setState({
                showError: true,
                errorMessage: getUserFriendlyErrorMessage(403),
              });
            }
            this._checkGetBlackbaudTokens();
          })
          .catch((e) => {
            if (e.ErrorCode === "TokenNotValid") {
              authApi = `v1/auth/blackbaud/url?userIdentityToken=${token}`;
              apiCall(authApi, "GET")
                .then((response) => {
                  this.openPopup(response.Url);
                })
                .catch((e) => {
                  if (e.ErrorCode === "TokenNotValid") {
                    this.setState({ showIndicator: false });

                    this.setState({
                      showError: true,
                      errorMessage: getUserFriendlyErrorMessage(
                        400,
                        "You are not authorized to use this application."
                      ),
                    });

                    return;
                  }
                  if (e.ErrorCode === "ServerError") {
                    if (e.IsLimitError && this.state.willDivertToLimitView) {
                      this.setState({
                        showLimitView: true,
                        limitError: e.Message,
                      });
                      return;
                    }
                    this.setState({
                      showError: true,
                      errorMessage: getUserFriendlyErrorMessage(
                        e.UserFriendlyErrorCode,
                        e.Message
                      ),
                    });
                  }
                });
            }
            if (e.ErrorCode === "ServerError") {
              if (e.IsLimitError && this.state.willDivertToLimitView) {
                this.setState({ showLimitView: true, limitError: e.Message });
                return;
              }
              this.setState({
                showError: true,
                errorMessage: getUserFriendlyErrorMessage(
                  e.UserFriendlyErrorCode,
                  e.Message
                ),
              });
            }
          });
      })
      .catch((e) => {
        this.setState({ showIndicator: false });
        console.log("error getting token");

        this.setState({
          showError: true,
          errorMessage: getUserFriendlyErrorMessage(9),
        });
      });
  }
  _isDonorSearchCategory(categories, category, value, newDate) {
    if (category === "DonorSearch Profile Url") {
      window.localStorage.setItem(
        `profileLink_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`,
        JSON.stringify({
          data: value,
          expires: moment().add(30, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        })
      );
      this.setState({ profileLink: value });
    }
    for (let i = 0; i < categories.length; i++) {
      if (
        categories[i].CategoryName === category &&
        categories[i].IsDisplayed
      ) {
        return true;
      }
    }
    return false;
  }

  _getDonorCategories(newDate = true) {
    this.setState({ showIndicator: true });
    let constituentBase = `${this.state.BlackbaudApiUrl}constituent/`;
    let constituentApi = `v1/constituents/${this.state.recordId}/customfields`;
    apiCall(
      constituentApi,
      "GET",
      {
        Authorization: "Bearer " + this.state.BlackbaudAccessToken,
        "bb-api-subscription-key": this.state.BlackbaudSubscriptionKey,
      },
      null,
      constituentBase
    )
      .then((nxtResponse) => {
        if (nxtResponse.value.length) {
          this.setState({ showIndicator: false });
          nxtResponse.value = nxtResponse.value.filter((category) => {
            return this._isDonorSearchCategory(
              this.state.savedCategories,
              category.category,
              category.value,
              newDate
            );
          });
          // eslint-disable-next-line
          nxtResponse.value.map((category) => {
            // eslint-disable-next-line
            this.state.savedCategories.map((customCategory) => {
              if (customCategory.CategoryName === category.category) {
                category.date = null;
                category.date_added = null;
                category.DisplayOrder = customCategory.DisplayOrder;
                category.date_modified = null;
              }
            });
          });
          nxtResponse.value = nxtResponse.value.sort((a, b) => {
            if (a.DisplayOrder > b.DisplayOrder) {
              return 1;
            }
            return -1;
          });
          if (nxtResponse.value.length) {
            if (newDate) {
              window.localStorage.setItem(
                `LastUpdate_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`,
                JSON.stringify({
                  data: moment().local().format("MM/DD/YYYY"),
                  expires: moment()
                    .add(30, "days")
                    .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                })
              );
              this.setState({
                lastUpdatedDate: newDate
                  ? moment().local().format("MM/DD/YYYY")
                  : "",
              });
            }
            window.localStorage.setItem(
              `donorScoreCategories_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`,
              JSON.stringify({
                data: nxtResponse.value,
                expires: moment()
                  .add(30, "days")
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              })
            );
            this.setState({
              donorScoreCategories: nxtResponse.value,
              showBatchMsg: false,
            });
          } else {
            this.setState({ showBatchMsg: true, showIndicator: false });
          }
        } else {
          window.localStorage.removeItem(
            `donorScoreCategories_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          );
          if (this.state.lastUpdatedDate) {
            this.setState({
              showBatchMsg: true,
              showIndicator: false,
              donorScoreCategories: null,
            });
          } else
            this.setState({
              showIndicator: false,
              donorScoreCategories: null,
            });
        }
      })
      .catch((e) => {
        this.setState({ showIndicator: false });
        if (e.Message === "Unauthorized") {
          this.setState({ BlackbaudAccessToken: "" });
          window.localStorage.removeItem("BlackbaudAccessToken");
          this._checkGetBlackbaudTokens(null, null, () => {
            this._getDonorCategories(newDate);
          });
          return;
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  }
  _checkDataSync(key, newDate) {
    let syncResponseAPI = `v1/commands/syncDonor/${key}`;
    apiCall(syncResponseAPI, "GET", {
      Authorization: "Bearer " + this.state.JwtAccessToken,
    })
      .then((syncResponse) => {
        window.localStorage.removeItem(
          `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
        );

        // this._getDonorCategories(newDate);

        this.setState({
          showPending: false,
          showPopUp: false,
          commandKey: null,
          firstPopUp: false,
          showError: false,
          lastSyncErrorMessage: "",
        });
        this._updateDonorCategoriesBtn.cancel();
        this.client.navigate({
          url: `constituent/records/${this.state.recordId}`,
        });
      })
      .catch((e) => {
        if (e.Message === "Unauthorized") {
          window.localStorage.removeItem("JwtAccessToken");
          this.setState({ JwtAccessToken: "" });
          this._checkCurrentUser(this.state.nameid, this.state.envId, () => {
            this._checkDataSync(key, newDate);
          });
          return;
        }
        if (e.ErrorCode === "CommandNotFound") {
          window.localStorage.removeItem(
            `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          );

          this._getDonorCategories(newDate);
          this.setState({
            showPending: false,
            commandKey: null,
            showPopUp: false,
            firstPopUp: false,
          });
          this._updateDonorCategoriesBtn.cancel();
        } else if (e.ErrorCode === "DonorNotFound") {
          window.localStorage.removeItem(
            `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          );
          this.setState({
            showPopUp: false,
            firstPopUp: false,
            showPending: false,
            commandKey: null,
            showError: true,
            lastSyncErrorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode
            ),
          });
        } else if (e.ErrorCode === "NotReady") {
          setTimeout(() => {
            if (this.state.willDivertToLimitView) {
              this.setState({ willDivertToLimitView: false });
            }

            this._checkDataSync(key, newDate);
          }, 5000);
        } else if (e.ErrorCode === "InvalidApiKey") {
          window.localStorage.removeItem(
            `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          );
          this.setState({
            showPopUp: false,
            firstPopUp: false,
            showPending: false,
            commandKey: null,
            showError: true,
            lastSyncErrorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode
            ),
          });
        } else if (e.ErrorCode === "DonorSearchApiError") {
          window.localStorage.removeItem(
            `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          );
          this.setState({
            showPopUp: false,
            firstPopUp: false,
            showPending: false,
            commandKey: null,
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
        if (e.ErrorCode === "ServerError") {
          window.localStorage.removeItem(
            `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          );
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }

          this.setState({
            showPopUp: false,
            firstPopUp: false,
            showPending: false,
            commandKey: null,
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  }
  _updateDonorCategoriesBtn = debounce(
    (newDate) => {
      if (this.state.willDivertToLimitView) {
        this.setState({ willDivertToLimitView: false });
      }

      this._updateDonorCategories(newDate);
    },
    10000,
    {
      leading: true,
      maxWait: 10000,
      trailing: false,
    }
  );
  _updateDonorCategories = (newDate, isFirstCall = false) => {
    if (this.state.showPending && !isFirstCall) {
      this.setState({ showPopUp: true });
      return;
    }
    let savedCategories = this._getStorageItem("categories");
    if (savedCategories) {
      if (!newDate) {
        this.setState({ savedCategories });
        this._getDonorCategories(newDate);
        return;
      }
      this.setState({ showIndicator: true, savedCategories });
      let api = `v1/donors/${this.state.recordId}/sync`;
      apiCall(api, "POST", {
        Authorization: "Bearer " + this.state.JwtAccessToken,
      })
        .then((response) => {
          if (newDate) {
            this.setState({ showDataInfoWarning: false });
          }
          window.localStorage.setItem(
            `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`,
            JSON.stringify({
              data: response.CommandKey,
              expires: moment()
                .add(30, "days")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            })
          );
          this.setState({
            showError: false,
            commandKey: response.CommandKey,
            showIndicator: false,
            showPopUp: true,
            firstPopUp: true,
          });
          setTimeout(() => {
            this._checkDataSync(response.CommandKey, newDate);
          }, 5000);
        })
        .catch((e) => {
          this.setState({ showIndicator: false });
          if (e.Message === "Unauthorized") {
            window.localStorage.removeItem("JwtAccessToken");
            this.setState({ JwtAccessToken: "" });
            this._checkCurrentUser(this.state.nameid, this.state.envId, () => {
              this._updateDonorCategories(newDate);
            });
            return;
          }
          if (e.ErrorCode === "PermissionError") {
            this._autoLogin();
          }
          if (e.ErrorCode === "InvalidApiKey") {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode || 7
              ),
            });
            return;
          }
          if (e.ErrorCode === "DonorNotFound") {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode || 1
              ),
            });
            return;
          }
          if (e.ErrorCode === "ServerError") {
            if (e.IsLimitError && this.state.willDivertToLimitView) {
              this.setState({ showLimitView: true, limitError: e.Message });
              return;
            }
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          }
        });
    } else {
      this.setState({ showIndicator: true });
      let api = `v1/customfieldcategories`;
      apiCall(api, "GET", {
        Authorization: "Bearer " + this.state.JwtAccessToken,
      })
        .then((categoriesResponse) => {
          window.localStorage.setItem(
            `categories`,
            JSON.stringify({
              data: categoriesResponse,
              expires: moment()
                .add(1, "day")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            })
          );
          this.setState({ savedCategories: categoriesResponse });
          if (!newDate) {
            this._getDonorCategories(newDate);
            return;
          }
          api = `v1/donors/${this.state.recordId}/sync`;
          apiCall(api, "GET", {
            Authorization: "Bearer " + this.state.JwtAccessToken,
          })
            .then((response) => {
              if (newDate) {
                this.setState({ showDataInfoWarning: false });
              }
              window.localStorage.setItem(
                `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`,
                JSON.stringify({
                  data: response.CommandKey,
                  expires: moment()
                    .add(30, "days")
                    .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                })
              );
              this.setState({
                showError: false,
                commandKey: response.CommandKey,
                showIndicator: false,
                showPopUp: true,
                firstPopUp: true,
              });
              setTimeout(() => {
                this._checkDataSync(response.CommandKey, newDate);
              }, 5000);
            })
            .catch((e) => {
              this.setState({ showIndicator: false });
              if (e.Message === "Unauthorized") {
                window.localStorage.removeItem("JwtAccessToken");
                this.setState({ JwtAccessToken: "" });
                this._checkCurrentUser(
                  this.state.nameid,
                  this.state.envId,
                  () => {
                    this._updateDonorCategories(newDate);
                  }
                );
                return;
              }
              if (e.ErrorCode === "InvalidApiKey") {
                this.setState({
                  showError: true,
                  errorMessage: getUserFriendlyErrorMessage(
                    e.UserFriendlyErrorCode || 7
                  ),
                });
                return;
              }
              if (e.ErrorCode === "DonorNotFound") {
                this.setState({
                  showError: true,
                  errorMessage: getUserFriendlyErrorMessage(
                    e.UserFriendlyErrorCode || 1
                  ),
                });
                return;
              }
              if (e.ErrorCode === "ServerError") {
                if (e.IsLimitError && this.state.willDivertToLimitView) {
                  this.setState({ showLimitView: true, limitError: e.Message });
                  return;
                }
                this.setState({
                  showError: true,
                  errorMessage: getUserFriendlyErrorMessage(
                    e.UserFriendlyErrorCode,
                    e.Message
                  ),
                });
              }
            });
        })
        .catch((e) => {
          this.setState({ showIndicator: false });
          if (e.Message === "Unauthorized") {
            window.localStorage.removeItem("JwtAccessToken");
            this.setState({ JwtAccessToken: "" });
            this._checkCurrentUser(this.state.nameid, this.state.envId, () => {
              this._updateDonorCategories(newDate);
            });
            return;
          }
          if (e.ErrorCode === "ServerError") {
            if (e.IsLimitError && this.state.willDivertToLimitView) {
              this.setState({ showLimitView: true, limitError: e.Message });
              return;
            }
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          }
        });
    }
  };
  _displayCategoryValue(data) {
    if (data.type === "Currency") {
      if (data.value === undefined || data.value === null) {
        data.value = 0;
      }
      return "$" + data.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else if (data.type === "Boolean") {
      if (data.value) {
        return "Yes";
      } else {
        return "No";
      }
    }
    return data.value;
  }
  openPopup = (url) => {
    const width = 715,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    let popUP = window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
    scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
    height=${height}, top=${top}, left=${left}`
    );
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(
      messageEvent,
      function (e) {
        if (
          e.origin !== `${window.location.protocol}//${window.location.host}`
        ) {
          return;
        }

        console.log("Received message!: ", e.data);
        if (e.data.JwtAccessToken) {
          window.localStorage.setItem(
            `JwtAccessToken`,
            JSON.stringify({
              data: e.data.JwtAccessToken,
              expires: moment()
                .add(10, "minutes")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            })
          );
        } else if (e.data.loginError) {
          window.localStorage.setItem(
            `loginError`,
            JSON.stringify({
              data: e.data,
              expires: moment()
                .add(3, "days")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            })
          );
        }
      },
      false
    );
    this._checkJWT(popUP);
  };
  _getResultsArea() {
    if (this.state.loggedIn || this._getStorageItem("JwtAccessToken")) {
      if (this.state.showLocation) {
        return (
          <div className="login-layout">
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flex: 1,
                width: "90%",
                height: 48,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <label className="no-location">
                DonorSearch data is unavailable for locations
              </label>
            </div>
          </div>
        );
      }
      if (
        !this.state.showLimitView &&
        this._getStorageItem(
          `donorScoreCategories_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
        )
      ) {
        return (
          <div style={{ paddingBottom: 26 }}>
            <div
              style={{
                width: "95%",
                marginLeft: 16,
                display: "flex",
                justifyContent: "space-between",
                marginTop: 24,
                paddingBottom: 4,
              }}
            >
              <label className="main-attribute attribute-row">Attribute</label>
              <label className=" main-value value-row">Value</label>
            </div>
            {this._getStorageItem(
              `donorScoreCategories_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
            ).map((dsData) => {
              return (
                <div
                  className="data-values"
                  style={{
                    width: "95%",
                    marginLeft: 16,
                    display: "flex",
                    paddingTop: 4,
                    paddingBottom: 4,
                    justifyContent: "space-between",
                    borderBottomColor: "#D8D8D8",
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                  }}
                >
                  <label className="attribute-row">{dsData.category}</label>
                  <label className="value-row">
                    {this._displayCategoryValue(dsData)}
                  </label>
                </div>
              );
            })}
          </div>
        );
      } else {
        if (!this.state.storageEnabled) {
          return getStorageDisabledView();
        }
        if (this.state.showLimitView) {
          return (
            <div className="updates-layout center-horizontal">
              <p className="no-updates">
                <i>{this.state.limitError}</i>
              </p>
            </div>
          );
        }
        return (
          <div className="updates-layout center-horizontal">
            <p className="no-updates">
              <i>No Updates found</i>
            </p>
          </div>
        );
      }
    } else {
      if (!this.state.storageEnabled) {
        return getStorageDisabledView();
      } else if (this.state.loginErrorMessage) {
        return (
          <div className="updates-layout center-horizontal">
            <p className="no-updates">
              <i>{this.state.loginErrorMessage}</i> <br />
            </p>
          </div>
        );
      }
      return (
        <div className="login-layout">
          <div
            onClick={this._login}
            className="sky-btn sky-btn-primary"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flex: 1,
              width: "90%",
              height: 48,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <label className="login-btn">Login</label>
          </div>
        </div>
      );
    }
  }

  _getInfoArea() {
    if (!this.state.storageEnabled || this.state.loginErrorMessage) {
      return null;
    }
    if (this.state.loggedIn || this._getStorageItem("JwtAccessToken")) {
      if (this.state.showLocation || this.state.showLimitView) {
        return <div />;
      }
      if (
        window.localStorage.getItem(
          `preferredAddressId_${this.state.recordId}_${this.state.envId}`
        )
      ) {
        return (
          <div className="address-layout">
            <label className="address-label">
              Address being reviewed:{" "}
              <span className="address-text">
                {this._getStorageItem(
                  `preferredAddressType_${this.state.recordId}_${this.state.envId}`
                ) +
                  " Address - " +
                  (this._getStorageItem(
                    `preferredAddress_${this.state.recordId}_${this.state.envId}`
                  )
                    ? this._getStorageItem(
                        `preferredAddress_${this.state.recordId}_${this.state.envId}`
                      )
                    : "Address is currently unusable")}
              </span>
            </label>
          </div>
        );
      } else {
        return (
          <div className="address-layout">
            <label className="address-label">Address being reviewed:</label>
          </div>
        );
      }
    } else {
      return (
        <div className="address-layout">
          <label className="address-label">
            You must login to access DonorSearch data.
          </label>
        </div>
      );
    }
  }
  _showButtonOptions() {
    if (
      (this.state.loggedIn || this._getStorageItem("JwtAccessToken")) &&
      !this.state.showLocation &&
      !this.state.showLimitView &&
      this.state.storageEnabled
    ) {
      return (
        <div className="top-layout">
          <div className="btn-layout" style={{ marginTop: 0 }}>
            <button
              className="sky-btn sky-btn-default"
              onClick={this._updateDonorCategoriesBtn}
              disabled={
                this._getStorageItem(
                  `preferredAddress_${this.state.recordId}_${this.state.envId}`
                ) === "" ||
                this._getStorageItem(
                  `preferredAddress_${this.state.recordId}_${this.state.envId}`
                ) === null ||
                this._getStorageItem(
                  `preferredAddress_${this.state.recordId}_${this.state.envId}`
                ) === undefined ||
                !this.state.permissionAllowed
              }
            >
              Update DonorSearch Data
            </button>
            {
              <button
                className="sky-btn sky-btn-default"
                onClick={() => {
                  if (this.state.willDivertToLimitView) {
                    this.setState({ willDivertToLimitView: false });
                  }
                  this._viewDonorSearchProfile();
                }}
                disabled={
                  this._getStorageItem(
                    `profileLink_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
                  ) === "" ||
                  this._getStorageItem(
                    `profileLink_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
                  ) === null ||
                  this._getStorageItem(
                    `profileLink_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
                  ) === undefined
                }
              >
                View DonorSearch Profile
              </button>
            }
            <button
              className="sky-btn sky-btn-default"
              disabled={!this.state.permissionAllowed}
              onClick={() => {
                if (this.state.willDivertToLimitView) {
                  this.setState({ willDivertToLimitView: false });
                }
                this._updateMainAddress();
              }}
            >
              Update Address
            </button>
          </div>
          {this._getStorageItem(
            `LastUpdate_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          ) ||
          this._getStorageItem(
            `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          ) ||
          this.state.showPending ? (
            <div className="last-updated">
              <label className="last-updated-text">Last DS Update</label>
              {this._getLastUpdateText()}
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    }
  }
  _getLastUpdateText = () => {
    if (
      !this.state.firstPopUp &&
      (this._getStorageItem(
        `commandKey_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
      ) ||
        this.state.showPending)
    ) {
      return <label className="last-updated-pending">Pending</label>;
    } else {
      return (
        <label className="last-updated-date">
          {this._getStorageItem(
            `LastUpdate_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
          )}
        </label>
      );
    }
  };
  _closeError = (errorType) => {
    if (this.state.lastSyncErrorMessage) {
      let cleanUpDonorUrl = `v1/donors/${this.state.recordId}/errors`;
      apiCall(cleanUpDonorUrl, "DELETE", {
        Authorization: "Bearer " + this.state.JwtAccessToken,
      })
        .then(() => {})
        .catch((e) => {
          if (e.ErrorCode === "DonorNotFound") {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode
              ),
              lastSyncErrorMessage: "",
            });
          }
          if (e.ErrorCode === "PermissionError") {
            this._autoLogin();
          }
          if (e.ErrorCode === "ServerError") {
            if (e.IsLimitError && this.state.willDivertToLimitView) {
              this.setState({ showLimitView: true, limitError: e.Message });
              return;
            }
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          }
        });
    }
    this.setState({
      showError: false,
      lastSyncErrorMessage: "",
      showDataInfoWarning: false,
    });
  };
  _showError() {
    if (
      (this.state.showError ||
        this.state.showDataInfoWarning ||
        !this.state.permissionAllowed) &&
      !this.state.showLimitView && !this.state.showLocation
    ) {
      return (
        <div
          className="address-layout"
          style={{ paddingBottom: 8, marginRight: 24 }}
        >
          <span style={{ marginLeft: 13, display: "block" }}>
            <label
              className="address-label"
              style={{ color: "#DD4B5C", display: "inline", marginLeft: 0 }}
            >
              {!this.state.permissionAllowed
                ? "You do not have permission to update this tile. Please speak to your administrator."
                : this.state.showDataInfoWarning
                ? "Address has been updated. Press the update data button to view new data. "
                : this.state.lastSyncErrorMessage
                ? this.state.lastSyncErrorMessage
                : this.state.errorMessage + " "}
            </label>
            {!this.state.permissionAllowed ? null : (
              <img
                onClick={() =>
                  this.state.showError
                    ? this._closeError("Error")
                    : this._closeError("Warning")
                }
                className="close-img"
                style={{ marginLeft: 2, display: "inline-block", top: 3 }}
                src={close}
                alt="close"
                width="17"
              />
            )}
          </span>
        </div>
      );
    }
  }
  _showBatchUpdated() {
    if (
      this.state.showBatchMsg &&
      !this.state.showLimitView &&
      !this.state.showPending
    ) {
      return (
        <div
          className="address-layout"
          style={{ paddingBottom: 8, marginRight: 24 }}
        >
          <span style={{ marginLeft: 13, display: "block" }}>
            <label
              className="address-label"
              style={{ color: "#239B56", display: "inline", marginLeft: 0 }}
            >
              Please check Ratings section for latest DS ratings update.
            </label>
          </span>
        </div>
      );
    }
  }

  _updateMainAddress = () => {
    let constituentBase = `${this.state.BlackbaudApiUrl}constituent/`;
    let constituentApi = `v1/constituents/${this.state.recordId}/addresses`;
    apiCall(
      constituentApi,
      "GET",
      {
        Authorization: "Bearer " + this.state.BlackbaudAccessToken,
        "bb-api-subscription-key": this.state.BlackbaudSubscriptionKey,
      },
      null,
      constituentBase
    )
      .then((nxtResponse) => {
        let values = nxtResponse.value.filter((address) => {
          if (address.formatted_address.replace(/\r\n/g, " ").trim()) {
            if (address.start) {
              if (moment(address.start).isBefore(moment())) {
                if (address.end) {
                  if (moment(address.end).isAfter(moment())) {
                    return 1;
                  }
                  return 0;
                }
                return 1;
              }
              return 0;
            } else if (address.end) {
              if (moment(address.end).isAfter(moment())) {
                return 1;
              }
              return 0;
            }
            return 1;
          }
          return 0;
        });
        values.map((address) => {
          address.preferred = false;
          if (address.id === this.state.preferredAddressId) {
            address.preferred = true;
          }
          return address;
        });
        if (values.length) {
          this.client
            .showModal({
              url: `${window.location.protocol}//${window.location.host}/updateaddress`,
              context: { addresses: values },
            })
            .modalClosed.then((context) => {
              if (context.address) {
                if (
                  context.address.id !== this.state.preferredAddressId ||
                  this._getFormattedAddress(context.address) !==
                    this.state.preferredAddress
                ) {
                  if (this.state.lastSyncErrorMessage) {
                    this.setState({
                      showError: false,
                      lastSyncErrorMessage: "",
                    });

                    let cleanUpDonorUrl = `v1/donors/${this.state.recordId}/errors`;
                    apiCall(cleanUpDonorUrl, "DELETE", {
                      Authorization: "Bearer " + this.state.JwtAccessToken,
                    })
                      .then(() => {})
                      .catch((e) => {
                        if (e.ErrorCode === "DonorNotFound") {
                          this.setState({
                            showError: true,
                            errorMessage: getUserFriendlyErrorMessage(
                              e.UserFriendlyErrorCode
                            ),
                            lastSyncErrorMessage: "",
                          });
                        }
                        if (e.ErrorCode === "ServerError") {
                          if (
                            e.IsLimitError &&
                            this.state.willDivertToLimitView
                          ) {
                            this.setState({
                              showLimitView: true,
                              limitError: e.Message,
                            });
                            return;
                          }
                          this.setState({
                            showError: true,
                            errorMessage: getUserFriendlyErrorMessage(
                              e.UserFriendlyErrorCode,
                              e.Message
                            ),
                          });
                        }
                      });
                  }
                  this._saveAddress(context.address, true, true);
                }
              }
            });
        } else {
          this.client.showError({
            closeText: "OK",
            description:
              "No valid addresses available, please update the constituent address.",
            title: "Error No Addresses",
          });
        }
      })
      .catch((e) => {
        if (e.Message === "Unauthorized") {
          this.setState({ BlackbaudAccessToken: "" });
          window.localStorage.removeItem("BlackbaudAccessToken");
          this._checkGetBlackbaudTokens(null, null, () => {
            this._updateMainAddress();
          });
          return;
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError) {
            e.Message = e.IsQuota
              ? `Updating address is unavailable due to quota limits. ${
                  e.Message.split(".")[1]
                }.`
              : `Updating address is unavailable due to rate limits. ${
                  e.Message.split(".")[1]
                }.`;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  };
  _viewDonorSearchProfile = () => {
    window.open(this.state.profileLink, "_blank");
  };
  _showWaiting() {
    if (this.state.showIndicator) {
      return <NXTWait blockOut={this.state.blockOut} />;
    }
    return null;
  }
  closePopUp = () => {
    this.setState({ showPopUp: false, firstPopUp: false, showPending: true });
    setTimeout(() => {
      if (this.state.commandKey === null) {
        this.setState({ showPending: false });
        this._updateDonorCategoriesBtn.cancel();
      }
    }, 300);
  };
  _showPendingPopUp() {
    if (this.state.showPopUp) {
      return (
        <div
          style={{
            position: "fixed",
            height: "100%",
            minWidth: "100%",
            backgroundColor: "#333333A0",
            top: 0,
          }}
        >
          <PopUpView
            message={
              "Your DonorSearch data request is pending. This page will refresh when the update is complete."
            }
            closePopUp={this.closePopUp}
          />
        </div>
      );
    }
  }

  render() {
    if (this.state.showLimitView && this.state.showIndicator) {
      this.setState({ showIndicator: false, blockOut: false });
    }
    try {
      if (this.state.nameid === "" && this.state.storageEnabled) {
        let nameid = this._getStorageItem("nameid");
        let envId = this._getStorageItem("envId");
        // eslint-disable-next-line
        this.state.nameid = nameid;
        // eslint-disable-next-line
        this.state.envId = envId;
        // eslint-disable-next-line
        this.state.recordId = window.localStorage.getItem("recordId");
      }
    } catch (e) {
      console.log(e);
    }
    return (
      <body
        style={{
          minHeight:
            this.state.loginErrorMessage ||
            this.state.showLimitView ||
            !this.state.storageEnabled ||
            this.state.showLocation
              ? 100
              : 210,
        }}
      >
        {this._showWaiting()}
        {this._showButtonOptions()}
        {this._showError()}
        {this._showBatchUpdated()}
        {this._getInfoArea()}
        {this._getResultsArea()}

        {this._showPendingPopUp()}
        <div
          style={{
            marginTop:
              this.state.loginErrorMessage ||
              this.state.showLimitView ||
              !this.state.storageEnabled ||
              this.state.showLocation
                ? 0
                : 20,
          }}
        ></div>
        <Footer />
      </body>
    );
  }
}

export default DonorSearchDataTile;
