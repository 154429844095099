import React, { Component } from 'react';
import "./nxtwait.scss"

class NXTWait extends Component {
  render () {
   
    return (
        <div className="wait-container"
        
      >
        <div className="block-out-container"
          style={{opacity: this.props.blockOut ? 1 : 0.5,}}
        ></div>
        <ds-wait></ds-wait>
      </div>
    );
  }
}
export default NXTWait;