import React, { Component } from "react";
import { AddinClient } from "@blackbaud/sky-addin-client";
import close from "../../components/images/close.png";
import "./batcherrorpopup.scss";
import {  getUserFriendlyErrorMessage } from "../../utils/restclient";

class BatchErrorPopUpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donorList: [],
      listName: "",
      closeOnClick: false,
      showClearErrors : true
    };
    this.client = new AddinClient({
      callbacks: {
        init: (args) => {
          this.setState({
            donorList: args.context.donorList,
            listName: args.context.listName,
            errorMsg: args.context.errorMsg,
            showClearErrors : args.context.showClearErrors
          });

          args.ready({
            showUI: true,
          });
        },
      },
    });
  }


  _close = () => {
    this.client.closeModal({ context: {closeError: this.state.closeOnClick} });
  };
  _getClearCheckInput(){
    if(this.state.showClearErrors)
    {
      return(
        <span className= "checkbox-close-container">
                  
        <input
          style={{  borderColor:'black', }}
          required={true}
          className="close-error-checkbox"
          id={"close"}
          type="checkbox"
          onClick={() =>
            this.setState({ closeOnClick: !this.state.closeOnClick })
          }
        ></input>
        <label for={"close"} className="close"></label>
        <label for={"close"} className="close-error-msg">
          {" "}
          close error message after viewing
        </label>
      </span>
      )
    }
    else{
      return(
        <span>
                  
        <input
          style={{ paddingRight: 20, borderColor:'black' }}
          className="close-error-checkbox disabled"
          id={"close"}
          type="checkbox"
          onClick={() =>
            this.setState({ closeOnClick: !this.state.closeOnClick })
          }
        ></input>
        <label for={"close"} className="close disabled"></label>
        <label for={"close"} className="close-error-msg disabled">
          {" "}
          close error message after viewing
        </label>
      </span>
      )
    }
  }
  _getErrors() {
      if(this.state.errorMsg)
      {
        if(!this.state.showClearErrors){
          return (
            <div className="batch-donor-list-container">
                  <label
                    className="batch-error-msg"
                  >
                    {this.state.errorMsg}
                  </label>
            </div>
          );
        }
        return (
            <div className="batch-donor-list-container">
                  <label
                    className="batch-error-msg"
                  >
                    {getUserFriendlyErrorMessage(this.state.errorMsg, null, this.state.listName )}
                  </label>
            </div>
          );
      }
    return (
      <div className="batch-donor-list-container">
       
        {this.state.donorList.map((errorItem) => {
          return (
              <div className="batch-donor-name-container">
            <label
              className="batch-donor-name"
              onClick={() => {
                this._openDonor(errorItem);
              }}
            >
              {errorItem.Name}
            </label>
            <label className="batch-error-donor-msg">{getUserFriendlyErrorMessage(errorItem.UserFriendlyErrorCode)}</label>
            </div>
          );
        })}
      </div>
    );
  }

  _openDonor(donor) {
    let donorLink = `https://host.nxt.blackbaud.com/constituent/records/${donor.DonorId}`;
    window.open(donorLink, "_blank");
    
  }
 

  render() {
    return (
      <body style={{ height: "100%", width: "100%" }}>
        <div
          className="modal"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "transparent",
            height: 432,
            width: 768,
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
        <div className="error-popup-top-spacer" />
          <div className="error-popup-container">
            <div>
              <span className="batch-popup-title-container">
                <label className="batch-error-popup-list-name">{this.state.listName}</label>
               {this._getClearCheckInput()}
              </span>
            </div>
            {this._getErrors()}
          </div>

          <img
            onClick={this._close}
            className="close-popup"
            src={close}
            alt="close"
            width="32"
          />
                  <div className="error-popup-bottom-spacer" />
        </div>
      </body>
    );
  }
}
export default BatchErrorPopUpModal;
