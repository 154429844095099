import React, { Component } from "react";

export function checkLocalStorage(){
    var check = 'check';
    try {
        window.localStorage.setItem(check, check);
        window.localStorage.removeItem(check);
        return true;
    } catch(e) {
        console.log(e)
        return false;
    }
}

export function getStorageDisabledView() {
  let browserName = getBrowserName();
  if(browserName === "unsupported" || browserName === "Safari")
  {
    return (
      <div className="updates-layout center-horizontal">
        <p className="no-updates">
          <i>Please enable cookies by going to your browser settings.</i> <br/>
          <i style={{marginLeft:5}}>1. </i>
        </p>
      </div>
    );
  }
  else if(browserName === "Chrome")
  {
    return (
      <div className="">
        <div className="browser-msg">
          <i>Please enable cookies:</i> <br/>
          <ol className="browserList">
          <li><i style={{marginLeft:8}}></i>Open your Chrome settings</li>
          <li><i style={{marginLeft:8}}></i>In the Privacy and Security section, select 'Cookies and other site data'</li>
          <li><i style={{marginLeft:8}}></i>Select 'Allow all cookies'</li>
          </ol>
        </div>
      </div>
    );
  }
  else if(browserName === "Firefox")
  {
    return (
      <div className="">
        <div className="browser-msg">
          <i>Please enable cookies:</i> <br/>
          <ol className="browserList">
          <li><i style={{marginLeft:8,}}></i>Open your Firefox settings</li>
          <li><i style={{marginLeft:8}}></i>Select 'Privacy & Security'</li>
          <li><i style={{marginLeft:8}}></i>Set browser privacy to standard</li>
          </ol>
        </div>
      </div>
    );
  }
  
  }
function getBrowserName(){
    let browserName = "unsupported";

      if (navigator.userAgent.indexOf("Chrome") !== -1) {
        browserName = "Chrome";
      } else if (navigator.userAgent.indexOf("Safari") !== -1) {
        browserName = "Safari";
      } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        browserName = "Firefox";
      }
    return browserName;
}