import * as env from "../env.json";
let baseHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Origin: "*",
};

export async function apiCall(
  api,
  method,
  auth = {},
  body = null,
  withBase = null,
  headers = null,
  attempt = 0
) {
  return new Promise(async (resolve, reject) => {
    let baseAPI = "";
    let requestHeaders = {};
    if (withBase) {
      baseAPI = withBase;
    } else {
      baseAPI = env.baseURL;
    }
    if (headers) {
      requestHeaders = { ...headers, ...auth };
    } else {
      requestHeaders = { ...baseHeaders, ...auth };
    }
    fetch(baseAPI + api, { method, headers: requestHeaders, body })
      .then(async (res) => {
        if(!res.ok)
        {
          console.log(res)
        }
        if (
          res.statusText === "Unauthorized" || 
          res.status === 401 ||
          res.status === 429 ||
          res.status === 403
        ) {
          if (res.status === 429 || res.status === 403) {
            try {
              return res.json().then(async (errorResult) => {
                let errorObject = _getCheckQuotaError(errorResult, res);

                if (errorObject) {
                  return Promise.reject(errorObject);
                } else {
                  return Promise.reject(errorResult);
                }
              }).catch((error)=>{
                if(error.UserFriendlyErrorCode)
                {
                  return Promise.reject(error);
                }
                if(res.status === 403)
                {
                 return Promise.reject({
                    ErrorCode: "PermissionError",
                    UserFriendlyErrorCode: res.status,
                    Message: res.statusText,
                  });
                }
              });
            } catch (e) {
              return res.text().then((text) => {
                return res.ok
                  ? text
                  : Promise.reject({
                      ErrorCode: "ServerError",
                      UserFriendlyErrorCode: res.status,
                      Message: res.statusText,
                    });
              });
            }
          }

          return res.text().then((text) => {
            return res.ok
              ? text
              : Promise.reject({
                  ErrorCode: "ServerError",
                  UserFriendlyErrorCode: res.status,
                  Message: res.status === 401? 'Unauthorized' : res.statusText,
                });
          });
        }
        if (res.headers.get("content-type") == null) {
          if (res.ok) {
            return { body: "" };
          } else {
            return Promise.reject({
              ErrorCode: "ServerError",
              UserFriendlyErrorCode: res.status? res.status : 500,
              Message: res.statusText? res.statusText : "There was an error",
            });
          }
        }
        try {
          return res.json().then((json) => {
            return res.ok ? json : Promise.reject(json.ErrorCode? json : {...json, ErrorCode: "ServerError", UserFriendlyErrorCode: res.status? res.status : 500,
            Message: res.statusText? res.statusText : "There was an error"});
          });
        } catch (e) {
          return res.text().then((text) => {
            return res.ok
              ? text
              : Promise.reject({
                  ErrorCode: "ServerError",
                  UserFriendlyErrorCode: res.status,
                  Message: res.statusText,
                });
          });
        }
      })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        if (e.IsLimitError && !e.IsQuota && attempt < 5) {
          attempt += 1;
          setTimeout(async () => {
            apiCall(api, method, auth, body, withBase, headers, attempt)
              .then((response) => {
                resolve(response);
              })
              .catch((e) => {
                console.log(e);
                reject(e);
              });
          }, e.Seconds * 1000);
        } else {
          reject(e);
        }
      });
  });
}

function _getCheckQuotaError(errorResult, res) {
  let errorObject = {};
  let retryMessage = "";
  let IsQuota = false;
  let retrySeconds = 0;
  if (errorResult.RetryReason) {
    if (errorResult.RetryReason === "quota") {
      IsQuota = true;
      let retryHours =
        parseInt(parseInt(errorResult.RetryAfterSeconds) / 3600) % 24;
      let retryMinutes =
        parseInt(parseInt(errorResult.RetryAfterSeconds) / 60) % 60;
      retrySeconds = parseInt(errorResult.RetryAfterSeconds) % 60;
      if (retryHours < 10) retryHours = "0" + retryHours;
      if (retryMinutes < 10) retryMinutes = "0" + retryMinutes;
      if (retrySeconds < 10) retrySeconds = "0" + retrySeconds;
      retryMessage = `The daily quota limit has been reached. The quota will be reset in ${retryHours}:${retryMinutes}:${retrySeconds}.`;
    } else {
      retrySeconds = errorResult.RetryAfterSeconds;
      retryMessage = `Rate Limit is exceeded. Try again in ${
        errorResult.RetryAfterSeconds
      } second${errorResult.RetryAfterSeconds > 1 ? "s" : ""}.`;
    }
  } else {
    if (errorResult.statusCode === 403) {
      IsQuota = true;
    } else if (errorResult.statusCode != 429) {
      return false;
    }
    let errorStrings = errorResult.message.split(" ");

    if (IsQuota) {
      retryMessage = `The daily quota limit has been reached. The quota will be reset in ${
        errorStrings[errorStrings.length - 1]
      }`;
    } else {
      retrySeconds = parseInt(errorStrings[errorStrings.length - 2]);
      retryMessage = `Rate Limit is exceeded. Try again in ${retrySeconds} second${
        retrySeconds > 1 ? "s" : ""
      }.`;
    }
  }
  errorObject = {
    ErrorCode: "ServerError",
    UserFriendlyErrorCode: res.status,
    Message: retryMessage,
    IsLimitError: true,
    IsQuota,
    Seconds: retrySeconds,
  };
  return errorObject;
}
export function getUserFriendlyErrorMessage(
  code = 500,
  message = "",
  extra = null
) {
  code = parseInt(code);
  if (code === 1) {
    message =
      "This record was not found in NXT. Please check NXT and try again.";
  } else if (code === 2) {
    message =
      "This record was not found in DonorSearch. Please check DonorSearch and try again.";
  } else if (code === 3) {
    message =
      "Address was not found in NXT. Please check the record in NXT and try again.";
  } else if (code === 4) {
    message =
      "Update failed to complete because access settings were changed during the update process. Please try again.";
  } else if (code === 5) {
    message = `There are no available constituents on list ${extra}. Please add constituents and try again.`;
  } else if (code === 6) {
    message = "List exceeds 1000 record limit.";
  } else if (code === 7) {
    message =
      "Api key invalidation. Please speak to your network administrator.";
  } else if (code === 8) {
    message =
      "Update failed due to NXT authentication error. Please log back in and re-run the request.";
  } else if (code === 9) {
    message = "Error obtaining user identity.";
  } else if( code === 10) {
    message = "Authentication has been denied. Please contact your DonorSearch administrator before trying again."
  } else if (code === 510) {
    message = "DonorSearch API error. Please contact your DonorSearch representative.";
  } else if (code === 403) {
    message = "You do not have permission to update this tile. Please speak to your administrator.";
  } else if (code === 500) {
    message = "Internal Server Error.";
  } else if (message === "Unauthorized") {
    message = "You are not authorized to use this application.";
  }
  return `Error Code: ${code} - ${message}`;
}
