import { decode } from 'js-base64';

export function parseToken (token) {
    var base64Url = token.split('.')[1];
 base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/').replace(/\\n/g, "\\n")  
 .replace(/\\'/g, "\\'")
 .replace(/\\"/g, '\\"')
 .replace(/\\&/g, "\\&")
 .replace(/\\r/g, "\\r")
 .replace(/\\t/g, "\\t")
 .replace(/\\b/g, "\\b")
 .replace(/\\f/g, "\\f")// eslint-disable-next-line
 .replace(/[\u0000-\u0019]+/g,""); 

 let payload = decode(base64Url)
 payload = payload.replace(/-/g, '+').replace(/_/g, '/').replace(/\\n/g, "\\n")  
 .replace(/\\'/g, "\\'")
 .replace(/\\"/g, '\\"')
 .replace(/\\&/g, "\\&")
 .replace(/\\r/g, "\\r")
 .replace(/\\t/g, "\\t")
 .replace(/\\b/g, "\\b")
 .replace(/\\f/g, "\\f")// eslint-disable-next-line
 .replace(/[\u0000-\u0019]+/g,""); 

  return JSON.parse(payload.trim())
};