import React from "react";

import ReactDOM from "react-dom";
import "./index.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import App from './App'
import DonorSearchDataTile from "./routes/donor-search-data/donorsearchdata"
import DonorSearchScoreTile from "./routes/donor-search-score/donorsearchscore";
import UpdateAddressModal from "./routes/update-address/updateaddress"
import LoginResultPage from './routes/login-result/loginresult'
import DonorSearchBatchTile from './routes/donor-search-batch/donorsearchbatch'
import BatchErrorPopUpModal from "./routes/batch-error-popup/batcherrorpopup"

import ViewDonorSearchProfileModal from './routes/view-donor-search-profile/viewdonorsearchprofile'
import * as serviceWorker from "./serviceWorker";
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/donorsearchbatch" component={DonorSearchBatchTile} />
      <Route path="/donorsearchdata" component={DonorSearchDataTile} />
      <Route path="/donorsearchscore" component={DonorSearchScoreTile} />
      <Route path="/updateaddress" component={UpdateAddressModal} />
      <Route path="/loginresult" component={LoginResultPage} />
      <Route path="/viewdonorsearchprofile" component={ViewDonorSearchProfileModal} />
      <Route path="/batcherrorpopup" component={BatchErrorPopUpModal} />
      <Route path="/" component={App} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
