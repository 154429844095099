import React, { Component } from 'react';
import { AddinClient, } from '@blackbaud/sky-addin-client';
import "./viewdonorsearchprofile.scss"
import close from "../../components/images/close.png";

class ViewDonorSearchProfileModal extends Component {


  constructor (props) {
    super(props);

    this.state={
      url:'',
  }
    this.client = new AddinClient({
      callbacks: {
        init: (args) => {
          this.setState({url: args.context.url});
         args.ready({
            showUI: true,
          });
        }
      }
    });
    
  }

  componentDidMount() {


  }

  componentWillUnmount() {
  }
 
  _close = () => {
    this.client.closeModal({context: {}});
  }
  
 
  render () {
    return (
      <body style={{height:'100%', width:'100%'}}>
      <div className="container" >
      <div className="wrapper">
      <div className="warning-wrapper">
      <label className="warning-text">
            This page is read only due to NXT system limitations
          </label>
      </div>
      
          <img
            onClick={this._close}
            className="close-img"
            src={close}
            alt="close"
            width="32"
          />
      </div>
      <iframe className="ds-webview" onclick={this._close} src={this.state.url} style={{display:'flex', flex:1}}  />
      </div>
     
      
      
      {/*<div style={{backgroundColor:'transparent',position:'absolute', top:0, bottom:0, left:0, right:0, height:654, width:1074, marginRight:'auto', marginLeft:'auto', marginTop:'auto', marginBottom:'auto'}}>
      <div style={{width:'100%', height:32,display:'flex', top:0, backgroundColor:'transparent'}}>
      <div style={{backgroundColor:'#FCECCF', height:'100%', flex:1, alignItems:'center', display:'flex'}}>
      <label  style={{ textAlign:'center', alignSelf:'center',margin:'auto', marginTop:'auto', marginBottom:'auto', color: "#333333",display:'flex',  }}>
            This page is view only due to NXT limitations
          </label>
      </div>
      
          <img
            onClick={this._close}
            className="close-img"
            src={close}
            alt="close"
            width="32"
            style={{marginLeft:16, marginTop:-5}}
          />
      </div>
      <iframe src={this.state.url} style={{borderWidth:0, borderColor:'transparent', height:664, width:1014, display:'flex', alignSelf:'center'}}>

</iframe>
      </div>*/}
      </body>
    );
  }
}
export default ViewDonorSearchProfileModal;