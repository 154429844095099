import React, { Component } from "react";
import {
  AddinClient,
  AddinTileSummaryStyle,
} from "@blackbaud/sky-addin-client";
import Cookies from "universal-cookie";
import "./donorsearchscore.scss";
import "../../main.scss";
import {apiCall} from '../../utils/restclient'
import {getVersion, getEnv} from '../../utils/buildinfo'
import moment from "moment";
class DonorSearchScoreTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      state: "",
      donorScore: "",
      userIdToken: "",
      JwtAccessToken:""
    };

    this.cookies = new Cookies();

    this.client = new AddinClient({
      callbacks: {
        init: (args) => {
          this.setState({
            envId: args.envId,
            recordId: args.context.recordId,
            context: JSON.stringify(args.context, undefined, 2),
          });

          args.ready({
            showUI: true,
            title: `Donor search score v(${getVersion()})(${getEnv()})`,
            tileConfig: {
              removeInset: true,
              summaryStyle: AddinTileSummaryStyle.Text,
              summaryText: "Ability to obtain the score for a donor",
              showHelp: false,
              showSettings: false,
            },
          });
        },
      },
    });
  }
  componentDidMount() {
    
    let jwt = this._getCookies("JwtAccessToken")
   if(jwt)
    {
      this.setState({JwtAccessToken: jwt})
    }
  }
  _getCookies(cookie){
    var match = document.cookie.match(new RegExp('(^| )' + cookie + '=([^;]+)'));
      if (match) {
        return match[2];
      }
      else{
           return null;
      }
  }
  _checkJWT(popUP){
    let jwt = window.localStorage.getItem("JwtAccessToken")? JSON.parse(window.localStorage.getItem("JwtAccessToken")).data : null
  
    if(jwt)
    {
      //alert(jwt)
      this.setState({JwtAccessToken: jwt})
      popUP.close()
    }
    else{
      setTimeout(()=>{
       this._checkJWT(popUP)
      }, 2000)
    }
  }
    _getUserTokenLogin(){

      this.client.getUserIdentityToken().then((token) => {
        this.setState({ userIdToken: token });
        document.cookie = `userIdToken=${token}; SameSite=None; Secure`
        let authApi = `v1/auth/blackbaud/url?userIdentityToken=${token}`;
        apiCall(authApi,"GET").then((response)=>{
          this.openPopup(response.Url)
        }).catch((e) => {
          if(e.ErrorCode === "TokenNotValid")
          {
            this.client.showError({
              closeText: "OK",
              description: "You are not authorized to use this application.",
              title: "Error",
            });
          }
        })
      });
    }
  
  
openPopup=(url)=> {
  const width = 600, height = 600
  const left = (window.innerWidth / 2) - (width / 2)
  const top = (window.innerHeight / 2) - (height / 2)

  let popUP= window.open(url, '',       
    `toolbar=no, location=no, directories=no, status=no, menubar=no, 
    scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
    height=${height}, top=${top}, left=${left}`
  )

  let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  let eventer = window[eventMethod];
  let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

  eventer(messageEvent,function(e) {
    console.log('origin: ', e.origin)
	  
    if( e.origin !== `${window.location.protocol}//${window.location.host}` ){ return }

    console.log('parent received message!: ', e.data);
    if(e.data.JwtAccessToken)
    {
      //alert(e.data.JwtAccessToken)
      window.localStorage.setItem(`JwtAccessToken`, JSON.stringify({data:e.data.JwtAccessToken,  expires:moment().add(3, 'days').format("YYYY-MM-DDTHH:mm:ss.SSSZ")}));
    }
    else if(e.data.loginError)
    {
      //alert(e.data.loginError)
      window.localStorage.setItem(`JwtAccessToken`, JSON.stringify({data:e.data.loginError,  expires:moment().add(3, 'days').format("YYYY-MM-DDTHH:mm:ss.SSSZ")}));
    }

  }, false);
  this._checkJWT(popUP)
 
}
  _checkScore() {
    if (this.state.donorScore !== "") {
      this.setState({ donorScore: "" });
    }
  }
  _getDonorScore = () => {
   
    let api = `v1/customfieldcategories`//`v1/donors/${this.state.recordId}`;
    apiCall(api,"GET", {Authorization: 'Bearer ' + this.state.JwtAccessToken}).then((response)=>{
      this.setState({ donorScore: JSON.stringify(response) });
    }).catch((e) => {
      let message = "There was an error";
      if (e.ErrorCode === "DonorNotFound") {
        message = "Donor not found";
      }
      else if(e==='Unauthorized') 
      {
        this.setState({JwtAccessToken: ''})
        document.cookie ="JwtAccessToken=; Max-Age=-99999999;  SameSite=None; Secure"
        return;
      }
      this.client.showError({
        closeText: "OK",
        description: message,
        title: "Error",
      });
      // this.client.hideWait()
    });
    
  };

  _login=() =>{
    let jwt = this._getCookies("JwtAccessToken")
    if(jwt)
    {
      this.setState({JwtAccessToken: jwt})
    }
    else
    {
    let token = this._getCookies("userIdToken");
    let authApi = "";

    if (token) {
      this.setState({ userIdToken: token });
      authApi = `v1/auth/blackbaud/url?userIdentityToken=${token}`;
      apiCall(authApi,"GET").then((response)=>{
        this.openPopup(response.Url)
      }).catch((e) => {
        if(e.ErrorCode === "TokenNotValid")
        {
          this._getUserTokenLogin()
        }
        else{
          alert(e)
        }
      })
    }
    else{
      this._getUserTokenLogin()
    }
    
  }
}
  _showDonorScore() {
    if (this.state.donorScore) {
      return (
        <div>
        <h1 className="center-horizontal">Donor Search Score</h1>
        <div
          className="score-label sky-control-label"
        >
          {this.state.donorScore}
        </div>
        </div>
      );
    }
  }
  render() {
    return (
      <body>
        
        <div className="center-horizontal">
        <button
            className="sky-btn sky-btn-default center-horizontal"
            style={{ display: "block", minWidth:250, marginTop:10}}
            onClick={this._login}
            disabled={this.state.JwtAccessToken !== ""}
          >
            Login
          </button>
          <button
            className="sky-btn sky-btn-default center-horizontal"
            style={{ display: "block", minWidth:250,  marginTop:10 }}
            onClick={this._getDonorScore}
            disabled={this.state.JwtAccessToken === ""}
          >
            View Donor Search Score
          </button>

          {this._showDonorScore()}
        </div>
      </body>
    );
  }
}

export default DonorSearchScoreTile;
