import React, { Component } from 'react';
import { AddinClient, } from '@blackbaud/sky-addin-client';
import "./updateaddress.scss"
class UpdateAddressModal extends Component {


  constructor (props) {
    super(props);

    this.state={
      addresses:[
        
      ],
    index:0,
  }
    this.client = new AddinClient({
      callbacks: {
        init: (args) => {
          this.setState({addresses: args.context.addresses});
          let addressSelected = false;
          for(let i = 0; i < args.context.addresses.length; i++)
          {
            if(args.context.addresses[i].preferred)
            {
              addressSelected = true
              document.getElementById("address_" + i).checked = true;
              this.setState({index:i})
            }
          }
          if(!addressSelected)
          {
            document.getElementById("address_0").checked = true;

          }
          args.ready({
            showUI: true,
          });
        }
      }
    });
    
  }

  componentDidMount() {

    
  }
  _getFormattedAddress(address){
    let addressString = ""
    if(address === null)
    {
      return ""
    }
    if(address.id)
    {
      addressString += address.address_lines
     // addressString = addressString? addressString + "\n" : addressString;
      addressString += address.city? " " + address.city + ", " : ', ';
      addressString +=  address.state + " " + address.postal_code
      addressString = addressString.replace(/null/g, "").trim()
      addressString = addressString.replace(/undefined/g, "").trim()
      if(addressString[addressString.length -1] === ',')
      {
        addressString = addressString.replace(/.$/g, "").trim()
      }
    }
    else if(address.AddressId)
    {
      addressString += address.Address1 + (address.Address2? " " + address.Address2 : "")
     // addressString = addressString? addressString + "\n" : addressString;
      addressString +=  address.City? " " + address.City + ", " : ', ';
      addressString += address.StateCode + " " + address.Zip
      addressString = addressString.replace(/null/g, "").trim();
      addressString = addressString.replace(/undefined/g, "").trim()
      if(addressString[addressString.length -1] === ',')
      {
        addressString = addressString.replace(/.$/g, "").trim()
      }
    }
    return addressString
  }
  componentWillUnmount() {
  }
 _addressClicked=(i)=>{
  document.getElementById("address_" + i).checked ="checked";
  document.getElementById("address_" + i).blur()
  this.setState({
    index: i
    });

 }
  _close = () => {
    this.client.closeModal({context: {}});
  }
  _getAddresses(){
    return(
  <div >
    {this.state.addresses.map((item, index) => (
      <div className="address-list-container">
      <input className="sky-form-control radio-input" type="radio" id={"address_" + index} name="address" value={index} onClick={()=>this._addressClicked(index)} />
       <div className="address-labels"><label onClick={()=>this._addressClicked(index)} className="address-type" >{item.type}<br></br><span className="address">{this._getFormattedAddress(item)}</span></label><br></br></div>
      </div>
    ))}
  </div>)
}
_onAddressChanged= e=> {
  this.setState({
    index: e.currentTarget.value
    });
    e.target.blur()
}
  _onSelect=(item)=>{
    this.client.closeModal({context: {address:this.state.addresses[this.state.index]}});
  }
 
 
  render () {
   
    return (
      <body style={{height:'100%', width:'100%'}}>
      <div className="modal" style={{position:'absolute', top:0, bottom:0, left:0, right:0, backgroundColor:"white", height:266, width:524, marginRight:'auto', marginLeft:'auto', marginTop:'auto', marginBottom:'auto'}}>
        <h2 className="update-address">Update Address</h2>
        <div className="address-selection-layout">
          {this._getAddresses()}
        </div>
      <div className="center-horizontal btn-layout" style={{display:'flex', justifyContent:'center', width:'100%'}}>
        <button className="sky-btn sky-btn-default" onClick={this._close} style={{display:"inline-block"}}>Cancel</button>
        <button className="sky-btn sky-btn-default" onClick={this._onSelect} style={{display:"inline-block"}}>Select</button>
      </div>
      </div>
      </body>
    );
  }
}
export default UpdateAddressModal;