import React, { Component } from 'react';
import "./popupview.scss"
import close from "../../images/closeX.png";

class PopUpView extends Component {


  constructor (props) {
    super(props);

    this.state={
     message:''
  }
    
  }

  _close = () => {
    this.client.closeModal({context: {}});
  }

  render () {
   
    return (
      <div className="popup-body-container">
      <div className="popup-img-container">
        <img className="popup-close" src={close} onClick={()=>this.props.closePopUp()} alt="close button"/>
        </div>
     <p className="message">{this.props.message}</p>
      </div>
    );
  }
}
export default PopUpView;