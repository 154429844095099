import React, { Component } from "react";
import {
  AddinClient,
  AddinTileSummaryStyle,
} from "@blackbaud/sky-addin-client";
import "./donorsearchbatch.scss";
import "../../main.scss";
import { apiCall, getUserFriendlyErrorMessage } from "../../utils/restclient";
import moment from "moment";
//import {Redirect} from 'react-router-dom'
import close from "../../components/images/close_tile.png";
import { parseToken } from "../../utils/parsetoken";
import Paging from "../../components/lib/paging/paging";
import NXTWait from "../../components/lib/nxt-wait/nxtwait";
import Footer from "../../components/lib/footer/footer";
import PopUpView from "../../components/lib/pop-up-view/popupview";
import { checkLocalStorage, getStorageDisabledView } from "../../utils/browserhelper";
import { checkVersion } from "../../utils/buildinfo";

class DonorSearchDataTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      envId: "",
      nameid: "",
      loggedIn: false,
      userIdToken: "",
      JwtAccessToken: "",
      BlackbaudSubscriptionKey: "",
      BlackbaudApiUrl: "",
      BlackbaudAccessToken: "",
      recordId: "",
      showError: false,
      showIndicator: false,
      currentPage: 1,
      subConstituentList: [],
      fullConstituentList: [],
      pageAdder: 0,
      blockOut: false,
      errorMessage: "",
      showPopUp: false,
      lastUpdateListIDs: [],
      showLimitView: false,
      limitError: "",
      willDivertToLimitView: true,
      storageEnabled: true,
      loginErrorMessage: null,
      showNoPermissionsView:false,
      checkedUser: false,
      redirect:false
    };
    this.client = new AddinClient({
      callbacks: {
        init: (args) => {
          if (checkLocalStorage()) {
            this.setState({
              envId: args.envId,
              context: JSON.stringify(args.context, undefined, 2),
            });
            let nameid = this._getStorageItem("nameid");
            let envId = this._getStorageItem("envId");
            window.localStorage.removeItem(`fullConstituentList`);
            window.localStorage.removeItem(`subConstituentList`);
            this._checkCurrentUser(nameid, envId);
          } else {
            this.setState({ storageEnabled: false });
          }

          args.ready({
            showUI: true,
            title: `DonorSearch batch data`,
            tileConfig: {
              removeInset: true,
              summaryStyle: AddinTileSummaryStyle.Text,
              summaryText: "",
              showHelp: false,
              showSettings: false,
            },
          });
          //help
        },
      },
    });
  }


  _autoLogin(callback = null) {
    let authApi = "v1/auth/userIdentityToken";
    apiCall(
      authApi,
      "POST",
      null,
      JSON.stringify({ UserIdentityToken: this.state.userIdToken })
    )
      .then((response) => {
        this.setState({
          JwtAccessToken: response.JwtAccessToken,
          loggedIn: true,
          // showError: false,
          showIndicator: false,
          blockOut: false,
          showNoPermissionsView: !response.IsBatchUpdateAllowed
        });
        window.localStorage.setItem(
          `JwtAccessToken`,
          JSON.stringify({
            data: response.JwtAccessToken,
            expires: moment().add(10, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          })
        );
        if(!response.IsBatchUpdateAllowed)
        {
          return
        }
        if(callback == "Cancel")
        {
          //cancel the TokenNotValid error callback only.
          this.setState({checkedUser: false});
          callback = null;
        }
        if(callback)
        {
          callback();
        }
        else{
        this._setUpTile(null, null);
        }
      })
      .catch((e) => {
        this.setState({ showIndicator: false, blockOut: false });
        if (e.ErrorCode === "TokenNotValid") {
          if (this.state.checkedUser) {
           return
          } else {
            this.setState({ checkedUser: true });
            window.localStorage.removeItem("JwtAccessToken");
            this._checkCurrentUser(this.state.nameid, this.state.envId, "Cancel");
          }          
          return;
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  }
  _checkCurrentUser(nameid, envId, callback = null) {
    this.setState({ showIndicator: true, blockOut: callback ==null ? true : false });
    this.client.getUserIdentityToken().then((token) => {
      let parsedToken = parseToken(token);
      window.localStorage.setItem(
        `envId`,
        JSON.stringify({
          data: parsedToken["environment/id"],
          expires: moment().add(2, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        })
      );
      window.localStorage.setItem(
        `nameid`,
        JSON.stringify({
          data: parsedToken.nameid,
          expires: moment().add(2, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        })
      );
      this.setState({ nameid: parsedToken.nameid, userIdToken: token });
      if (
        parsedToken.nameid === nameid &&
        parsedToken["environment/id"] === envId
      ) {
        let jwt = this._getStorageItem("JwtAccessToken");
        if (jwt) {
          this.setState({
            JwtAccessToken: jwt,
            loggedIn: true,
            showIndicator: false,
            blockOut: false,
          });
          this._setUpTile(parsedToken.nameid, jwt);

          return;
        }
      } else {
        window.localStorage.removeItem("JwtAccessToken");
        window.localStorage.removeItem("userIdToken");
      }
      this._autoLogin(callback);
    });
  }
  _checkJWT(popUP) {
    let jwt = this._getStorageItem("JwtAccessToken");
    let error =  window.localStorage.getItem("loginError");
    if (jwt) {
      this.setState({
        JwtAccessToken: jwt,
        loggedIn: true,
        showIndicator: false,
      });
      if(this._getStorageItem("IsBatchUpdateAllowed") == null)
      {
        this.setState({showNoPermissionsView:true})
      }
      else{
      this._setUpTile();
      }
      setTimeout(() => {
        if (!popUP.closed) {
          popUP.close();
        }
      }, 500);
    } else if (error) {
      error = JSON.parse(window.localStorage.getItem("loginError")).data;
      this.setState({
        showIndicator: false,
        JwtAccessToken: "",
        loggedIn: false,
      loginErrorMessage: error.UserFriendlyErrorCode === 10? getUserFriendlyErrorMessage(error.UserFriendlyErrorCode) : null
      });
      window.localStorage.removeItem("loginError");
      window.localStorage.removeItem("JwtAccessToken");
      setTimeout(() => {
        if (!popUP.closed) {
          popUP.close();
        }
      }, 500);
    } else if (popUP.closed) {
      this.setState({ showIndicator: false });
    } else {
      setTimeout(() => {
        this._checkJWT(popUP);
      }, 1000);
    }
  }
  _closeError = (errorType) => {
    if (errorType === "Error") {
      this.setState({ showError: false });
    }
  };
  _deleteErrors= (listId)=>{
    let cleanUpListUrl = `v1/lists/donors/${listId}/errors`;
              apiCall(cleanUpListUrl, "DELETE", {
                Authorization: "Bearer " + this.state.JwtAccessToken,
              })
                .then((listResponse) => {
                  this.state.fullConstituentList.map((item, index) => {
                    if (item.ListId === listResponse.ListId) {
                      item.HasSyncErrors = false;
                    }
                    return null;
                  });

                  let sortedList = this._sortList(
                    this.state.fullConstituentList,
                    false
                  );
                  this.setState({
                    fullConstituentList: sortedList,
                    checkedUser:false
                  });
                  this.setListPage(this.state.currentPage, sortedList);
                })
                .catch((e) => {
                  this.setState({ showIndicator: false });
                  if (e.Message === "Unauthorized") {
                    if (this.state.checkedUser) {
                      this.setState({
                        showError: true,
                        errorMessage: getUserFriendlyErrorMessage(
                          e.UserFriendlyErrorCode,
                          e.Message
                        ),
                      });
                    } else {
                      this.setState({ checkedUser: true });
                      window.localStorage.removeItem("JwtAccessToken");
                      this._checkCurrentUser(this.state.nameid, this.state.envId, ()=>{this._deleteErrors(listId)});
                    }
                    return;
                  }
                  if (e.ErrorCode === "ListNotFound") {
                    this.setState({
                      showError: true,
                      errorMessage: getUserFriendlyErrorMessage(
                        404,
                        "List was not found."
                      ),
                    });
                  }
                  if(e.ErrorCode === 'PermissionError')
                  {
                    this._autoLogin();
                  }
                  if (e.ErrorCode === "ServerError") {
                    if (e.IsLimitError && this.state.willDivertToLimitView) {
                      this.setState({
                        showLimitView: true,
                        limitError: e.Message,
                      });
                      return;
                    }
                    this.setState({
                      showError: true,
                      errorMessage: getUserFriendlyErrorMessage(
                        e.UserFriendlyErrorCode,
                        e.Message
                      ),
                    });
                  }
                });
  }
  _deleteExpiredItems() {
    for (var item in window.localStorage) {
      if (
        item !== "BlackbaudSubscriptionKey" &&
        item !== "BlackbaudApiUrl" &&
        item !== "BlackbaudAccessToken" &&
        item !== "userIdToken" &&
        item !== "randid" &&
        item !== "length" &&
        item !== "key" &&
        item !== "getItem" &&
        item !== "setItem" &&
        item !== "removeItem" &&
        item !== "clear" &&
        item !== "recordId"
      ) {
        let type = item.substring(0, item.indexOf("_"));
        if (
          item !==
          `${type}_${this.state.recordId}_${this.state.nameid}_${this.state.envId}`
        ) {
          try {
            let data = JSON.parse(window.localStorage[item]);
            if (moment(data.expires).isBefore(moment().local())) {
              window.localStorage.removeItem(item);
            }
          } catch (e) {}
        }
      }
    }
  }
  _displayList = (listItem, index) => {
    if (listItem.IsPending) {
      return (
        <div
          className="data-values"
          style={{
            width: "100%",
            display: "flex",
            marginTop: 16,
            paddingBottom: 4,
            justifyContent: "space-between",
          }}
        >
          <span className="name-column-container">
            <label className="list-number batch-disabled">
              {index + 1 + this.state.pageAdder}
            </label>
            <input
              disabled={true}
              className="list-checkbox"
              id={"check_" + index}
              type="checkbox"
              onClick={() => this._listChecked(index)}
              checked={listItem.IsChecked ? "checked" : null}
            ></input>
            <label
              for={"check_" + index}
              className="blank-checkBox batch-disabled"
            ></label>
            <label
              for={"check_" + index}
              className="name-column-value batch-disabled"
            >
              {listItem.Name}
            </label>
          </span>

          <span className="description-column-container">
            <label className="description-column-value batch-disabled">
              {listItem.Description}
            </label>
            {listItem.HasSyncErrors ? (
              <label
                className="error-column-value"
                onClick={() => {
                  this._showListErrors(listItem.ListId, listItem.Name);
                }}
              >
                view errors
              </label>
            ) : null}
          </span>
          <label className="record-count-column-value batch-disabled">
            {listItem.CountRecords}
          </label>
          {this._getLastUpdateValue(listItem)}
        </div>
      );
    } else {
      return (
        <div
          className="data-values"
          style={{
            width: "100%",
            display: "flex",
            marginTop: 16,
            paddingBottom: 4,
            justifyContent: "space-between",
          }}
        >
          <span className="name-column-container">
            <label className="list-number">
              {index + 1 + this.state.pageAdder}
            </label>
            <input
              className="list-checkbox"
              id={"check_" + index}
              type="checkbox"
              onClick={() => this._listChecked(index)}
              checked={listItem.IsChecked ? "checked" : null}
            ></input>
            <label for={"check_" + index} className="blank-checkBox"></label>
            <label for={"check_" + index} className="name-column-value">
              {listItem.Name}
            </label>
          </span>

          <span className="description-column-container">
            <label className="description-column-value">
              {listItem.Description}
            </label>
            {listItem.HasSyncErrors ? (
              <label
                className="error-column-value"
                onClick={() => {
                  this._showListErrors(listItem.ListId, listItem.Name);
                }}
              >
                view errors
              </label>
            ) : null}
          </span>
          <label className="record-count-column-value">
            {listItem.CountRecords}
          </label>
          {this._getLastUpdateValue(listItem)}
        </div>
      );
    }
  };
  _getInfoArea() {
    if (this.state.loggedIn || this._getStorageItem("JwtAccessToken")) {
    } else {
      if (!this.state.storageEnabled || this.state.loginErrorMessage) {
        return null;
      }
      return (
        <div className="info-layout">
          <label className="info-label">
            You must login to access DonorSearch data.
          </label>
        </div>
      );
    }
  }
  _getNoPermissionView(){
    return(
    <div className="updates-layout center-horizontal">
        <p className="no-updates">
          <i>You do not have permission to access this tile. Please speak to your administrator.</i> <br/>
        </p>
      </div>
    );
  }
  _getLastUpdateValue(listItem) {
    if (listItem.IsPending) {
      return <label className="pending">Pending</label>;
    } else {
      return (
        <label className="last-update-column-value">
          {listItem.LastSyncDateTime
            ? moment(listItem.LastSyncDateTime).format("MM/DD/YYYY")
            : "N/A"}
        </label>
      );
    }
  }
  _getList = () => {
    let listApi = `v1/lists/donors`;
    this.setState({ showIndicator: true });
    apiCall(listApi, "GET", {
      Authorization: "Bearer " + this.state.JwtAccessToken,
    })
      .then((listResponse) => {
        listResponse.map((item) => {
          item.IsChecked = false;
          return null;
        });
        // eslint-disable-next-line
        let sortedList = this._sortList(listResponse);

        this.setState({
          fullConstituentList: sortedList,
          showIndicator: false,
          checkedUser:false
        });
        if (listResponse.length) {
          this.setListPage(1, sortedList);
        }
      })
      .catch((e) => {
        this.setState({ showIndicator: false });
        if (e.Message === "Unauthorized") {
          if (this.state.checkedUser) {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          } else {
            this.setState({ checkedUser: true });
            window.localStorage.removeItem("JwtAccessToken");
            this._checkCurrentUser(this.state.nameid, this.state.envId, false);
          }
          return;
        }
        if(e.ErrorCode === 'PermissionError')
        {
          this._autoLogin();
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  };
  _getResultsArea() {

    if (this.state.loggedIn || this._getStorageItem("JwtAccessToken")) {
      if (this.state.showLimitView) {
        return (
          <div className="batch-updates-layout center-horizontal">
            <p className="no-list">
              <i>{this.state.limitError}</i>
              <br />
              <i>
                {
                  "Any pending list has been saved and will resume at that time."
                }
              </i>
            </p>
          </div>
        );
      }
      if (!this.state.storageEnabled) {
        return getStorageDisabledView();
      }
      if(this.state.showNoPermissionsView){
        return this._getNoPermissionView();
      }
      return (
        <div style={{ paddingBottom: 16 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 6,
              paddingBottom: 6,
              borderBottomWidth: 1,
              borderColor: "#CDCFD2",
              borderBottomStyle: "solid",
            }}
          >
            <label className="name-column-title">List Name</label>
            <label className="description-column-title">Description</label>
            <label className="record-count-column-title">Record Count</label>
            <label className="last-update-column-title">Last Update</label>
          </div>
          {JSON.parse(window.localStorage.getItem("subConstituentList")) ? (
            JSON.parse(window.localStorage.getItem("subConstituentList"))
              .length ? (
              <div
                style={{
                  marginTop: 5,
                  paddingBottom: 24,
                  borderBottomWidth: 1,
                  borderColor: "#CDCFD2",
                  borderBottomStyle: "solid",
                }}
              >
                {JSON.parse(
                  window.localStorage.getItem("subConstituentList")
                ).map((listItem, index) => {
                  return this._displayList(listItem, index);
                })}
              </div>
            ) : (
              <div className="batch-updates-layout center-horizontal">
                <p className="no-list">
                  <i>
                    No lists available. Please create a constituent list and
                    retry.
                  </i>
                </p>
              </div>
            )
          ) : (
            <div className="batch-updates-layout center-horizontal">
              <p className="no-list">
                <i>
                  No lists available. Please create a constituent list and
                  retry.
                </i>
              </p>
            </div>
          )}
          {JSON.parse(window.localStorage.getItem("fullConstituentList")) ? (
            JSON.parse(window.localStorage.getItem("fullConstituentList"))
              .length ? (
              <div style={{ marginTop: 24, marginLeft: 16 }}>
                <Paging
                  maxPage={Math.ceil(
                    JSON.parse(
                      window.localStorage.getItem("fullConstituentList")
                    ).length / 10
                  )}
                  currentPage={this.state.currentPage}
                  updateList={(page) => this.setListPage(page)}
                />
              </div>
            ) : null
          ) : null}
        </div>
      );
    } else {
      if (!this.state.storageEnabled) {
        return getStorageDisabledView();
      }
      else if(this.state.showNoPermissionsView){
        return this._getNoPermissionView();
      }
      else if(this.state.loginErrorMessage)
      {
          return (
            <div className="updates-layout center-horizontal">
              <p className="no-updates">
                <i>{this.state.loginErrorMessage}</i> <br/>
              </p>
            </div>
          );
    
      }
      return (
        <div className="login-layout">
          <div
            onClick={this._login}
            className="sky-btn sky-btn-primary"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flex: 1,
              width: "90%",
              height: 48,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <label className="login-btn">Login</label>
          </div>
        </div>
      );
    }
  }
  
  _getStorageItem(name, andData = true) {
    try {
      if (andData) {
        if (window.localStorage.getItem(name)) {
          return JSON.parse(window.localStorage.getItem(name)).data;
        } else {
          return null;
        }
      } else return window.localStorage.getItem(name);
    } catch (e) {
      return null;
    }
  }
  _getUserTokenLogin(andLogin = true) {
    this.client
      .getUserIdentityToken()
      .then((token) => {
        if (!andLogin) {
          return token;
        }
        this.setState({ userIdToken: token, showIndicator: true });
        window.localStorage.setItem("userIdToken", token);
        let authApi = "v1/auth/userIdentityToken";

        apiCall(
          authApi,
          "POST",
          null,
          JSON.stringify({ UserIdentityToken: token })
        )
          .then((response) => {
            this.setState({
              JwtAccessToken: response.JwtAccessToken,
              loggedIn: true,
              showIndicator: false,
            });
            window.localStorage.setItem(
              `JwtAccessToken`,
              JSON.stringify({
                data: response.JwtAccessToken,
                expires: moment()
                  .add(10, "minutes")
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              })
            );
            if(!response.IsBatchUpdateAllowed)
            {
              this.setState({showNoPermissionsView:true})
              return;
            }
            this._setUpTile();
          })
          .catch((e) => {
            if (e.ErrorCode === "TokenNotValid") {
              authApi = `v1/auth/blackbaud/url?userIdentityToken=${token}`;
              apiCall(authApi, "GET")
                .then((response) => {
                  this._openPopup(response.Url);
                })
                .catch((e) => {
                  if (e.ErrorCode === "TokenNotValid") {
                    this.setState({ showIndicator: false });

                    this.setState({
                      showError: true,
                      errorMessage: getUserFriendlyErrorMessage(
                        400,
                        "You are not authorized to use this application."
                      ),
                    });

                    return;
                  }
                  if (e.ErrorCode === "ServerError") {
                    this.setState({
                      showError: true,
                      errorMessage: getUserFriendlyErrorMessage(
                        e.UserFriendlyErrorCode,
                        e.Message
                      ),
                    });
                  }
                });
            }
            if (e.ErrorCode === "ServerError") {
              if (e.IsLimitError && this.state.willDivertToLimitView) {
                this.setState({ showLimitView: true, limitError: e.Message });
                return;
              }
              this.setState({
                showError: true,
                errorMessage: getUserFriendlyErrorMessage(
                  e.UserFriendlyErrorCode,
                  e.Message
                ),
              });
            }
          });
      })
      .catch((e) => {
        this.setState({ showIndicator: false });

        this.setState({
          showError: true,
          errorMessage: getUserFriendlyErrorMessage(9),
        });
      });
  }
  _listChecked(index) {
    let realIndex = (this.state.currentPage - 1) * 10 + index;
    if (this.state.fullConstituentList[realIndex].IsChecked) {
      // eslint-disable-next-line
      this.state.fullConstituentList[realIndex].IsChecked = false;
    } else {
      // eslint-disable-next-line
      this.state.fullConstituentList[realIndex].IsChecked = true;
    }
    const startNumber = (this.state.currentPage - 1) * 10;
    const subList = this.state.fullConstituentList.slice(
      startNumber,
      startNumber + 10
    );
    window.localStorage.setItem(
      `fullConstituentList`,
      JSON.stringify(this.state.fullConstituentList)
    );
    window.localStorage.setItem(`subConstituentList`, JSON.stringify(subList));
    this.forceUpdate();
  }
  _login = () => {
    this.setState({ showError: false });
    let jwt = this._getStorageItem("JwtAccessToken");
    if (jwt) {
      this.setState({ JwtAccessToken: jwt, loggedIn: true, showIndicator:true });
      setTimeout(()=>{
        this._setUpTile();
      },300)
    } else {
      this.setState({ showIndicator: true });
      let token = window.localStorage.getItem("userIdToken");
      let authApi = "";

      if (token) {
        this.setState({ userIdToken: token });

        authApi = `v1/auth/blackbaud/url?userIdentityToken=${token}`;
        apiCall(authApi, "GET")
          .then((response) => {
            this._openPopup(response.Url);
          })
          .catch((e) => {
            if (e.ErrorCode === "TokenNotValid") {
              this._getUserTokenLogin();
            } else {
              this.setState({ showIndicator: false });
              if (e.ErrorCode === "ServerError") {
                if (e.IsLimitError && this.state.willDivertToLimitView) {
                  this.setState({ showLimitView: true, limitError: e.Message });
                  return;
                }
                this.setState({
                  showError: true,
                  errorMessage: getUserFriendlyErrorMessage(
                    e.UserFriendlyErrorCode,
                    e.Message
                  ),
                });
              }
            }
          });
      } else {
        this._getUserTokenLogin();
      }
    }
  };
  _openPopup = (url) => {
    const width = 715,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    let popUP = window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
    scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
    height=${height}, top=${top}, left=${left}`
    );
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(
      messageEvent,
      function (e) {
        console.log("origin: ", e.origin);

        if (
          e.origin !== `${window.location.protocol}//${window.location.host}`
        ) {
          return;
        }

        console.log("parent received message!: ", e.data);
        if (e.data.JwtAccessToken) {
          window.localStorage.setItem(
            `JwtAccessToken`,
            JSON.stringify({
              data: e.data.JwtAccessToken,
              expires: moment()
                .add(10, "minutes")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            })
          );
        } else if (e.data.loginError) {
          window.localStorage.setItem(
            `loginError`,
            JSON.stringify({
              data: e.data,
              expires: moment()
                .add(2, "days")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            })
          );
        }
      },
      false
    );
    this._checkJWT(popUP);
  };

  _setUpTile(nameid, jwt, callback = null) {
    this._deleteExpiredItems();


    if (callback) {
      this.setState({ showIndicator: false });
      callback();
      return;
    }
    this._getList();

  }
  _showButtonOptions() {
    if (
      this.state.loggedIn &&
      !this.state.showLimitView &&
      this.state.storageEnabled &&
      !this.state.showNoPermissionsView
    ) {
      return (
        <div className="top-layout">
          <div className="btn-layout" style={{ marginTop: 0 }}>
            <button
              className="sky-btn sky-btn-default"
              onClick={() => {
                if (this.state.willDivertToLimitView) {
                  this.setState({ willDivertToLimitView: false });
                }
                this._updateList()
              }}
              disabled={
                this.state.fullConstituentList.filter((item) => {
                  return item.IsChecked;
                }).length === 0
              }
            >
              Perform Update
            </button>
            <button
              className="sky-btn sky-btn-default"
              onClick={() => {
                if (this.state.willDivertToLimitView) {
                  this.setState({ willDivertToLimitView: false });
                }
                this._getList();
              }}
              disabled={false}
            >
              Refresh List
            </button>
          </div>
        </div>
      );
    }
  }
  _showError() {
    if (this.state.showError) {
      return (
        <div className="info-layout" style={{ paddingBottom: 8 }}>
          <label
            className="info-label"
            style={{ color: "#DD4B5C", display: "inline" }}
          >
            {this.state.errorMessage + " "}
          </label>
          <img
            onClick={() =>
              this.state.showError
                ? this._closeError("Error")
                : this._closeError("Warning")
            }
            className="close-img"
            style={{ marginLeft: 2, display: "inline-block", top: 3 }}
            src={close}
            alt="close"
            width="17"
          />
        </div>
      );
    }
  }
  _showListErrors(listId, name) {
    let getErrorUrl = `v1/lists/donors/${listId}/errors`;
    this.setState({ showIndicator: true });
    apiCall(getErrorUrl, "GET", {
      Authorization: "Bearer " + this.state.JwtAccessToken,
    })
      .then((listErrorResponse) => {
        let showClearErrors = true;
        this.setState({ showIndicator: false, checkedUser:false});
        if (
          listErrorResponse.ListItemErrors.length === 0 &&
          listErrorResponse.GeneralUserFriendlyErrorCode === null
        ) {
          listErrorResponse.GeneralUserFriendlyErrorCode =
            "Another user has already cleared the errors.";
          showClearErrors = false;
        }
        this.client
          .showModal({
            url: `${window.location.protocol}//${window.location.host}/batcherrorpopup`,
            context: {
              donorList: listErrorResponse.ListItemErrors,
              errorMsg: listErrorResponse.GeneralUserFriendlyErrorCode,
              listName: name,
              showClearErrors,
            },
          })
          .modalClosed.then((context) => {
            if (context.closeError) {
              this._deleteErrors(listId)
            }
          });
      })
      .catch((e) => {
        this.setState({ showIndicator: false });
        if (e.Message === "Unauthorized") {
          if (this.state.checkedUser) {
            this.setState({
              showError: true,
              errorMessage: getUserFriendlyErrorMessage(
                e.UserFriendlyErrorCode,
                e.Message
              ),
            });
          } else {
            this.setState({ checkedUser: true });
            window.localStorage.removeItem("JwtAccessToken");
            this._checkCurrentUser(this.state.nameid, this.state.envId, ()=>{this._showListErrors(listId, name)});
          }
          return;
        }
        if (e.ErrorCode === "ListNotFound") {
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              404,
              "List was not found."
            ),
          });
        }
        if(e.ErrorCode === 'PermissionError')
        {
          this._autoLogin();
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  }
  _sortList(list, auto = true) {
    this.setState({ lastUpdateListIDs: [] });

    let dateList = list.filter((item) => {
      return item.LastSyncDateTime !== null && !item.IsPending;
    });
    let otherList = list.filter((item) => {
      return item.LastSyncDateTime === null && !item.IsPending;
    });

    let pendingList = list.filter((item) => {
      return item.IsPending;
    });

    dateList.sort((a, b) => {
      if (moment(a.LastSyncDateTime).isAfter(moment(b.LastSyncDateTime))) {
        return -1;
      } else if (
        moment(a.LastSyncDateTime).isBefore(moment(b.LastSyncDateTime))
      ) {
        return 1;
      } else {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
          return -1;
        }
        if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    });

    pendingList.sort((a, b) => {
      if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
        return -1;
      }
      if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    otherList.sort((a, b) => {
      if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
        return -1;
      }
      if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    let fullList = [];
    fullList = fullList.concat(pendingList, dateList, otherList);
    return fullList;
  }
  _showPendingPopUp() {
    if (this.state.showPopUp) {
      return (
        <div
          style={{
            position: "fixed",
            height: "100%",
            minWidth: "100%",
            backgroundColor: "#333333A0",
            top: 0,
            zIndex:10
          }}
        >
          <PopUpView
            message={
              "This request is in progress and may take a while. Please click the Refresh List button to see updated results."
            }
            closePopUp={this.closePopUp}
          />
        </div>
      );
    }
  }
  _showWaiting() {
    if (this.state.showIndicator) {
      return <NXTWait blockOut={this.state.blockOut} />;
    }
    return null;
  }

  _updateList = () => {
    let listToUpdate = [];
    const checkedList = this.state.fullConstituentList
      .filter((listItem) => {
        return listItem.IsChecked;
      })
      .map((item) => {
        listToUpdate = listToUpdate.concat(item.ListId);
      });
    this.setState({ showIndicator: true });
    const updateApi = "v1/lists/donors/sync";
    apiCall(
      updateApi,
      "POST",
      {
        Authorization: "Bearer " + this.state.JwtAccessToken,
      },
      JSON.stringify(listToUpdate)
    )
      .then((updateResponse) => {
        this.setState({
          lastUpdateListIDs: listToUpdate,
          showError: false,
          showIndicator: false,
          showPopUp: true,
          checkedUser: false
        });
      })
      .catch((e) => {
        this.setState({ showIndicator: false });

        if (e.ErrorCode === "IdentityCollectionNotValid") {
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              400,
              "Can not update empty list."
            ),
          });
        }
        if(e.ErrorCode === 'PermissionError')
        {
          this._autoLogin();
        }
        if (e.ErrorCode === "ServerError") {
          if (e.IsLimitError && this.state.willDivertToLimitView) {
            this.setState({ showLimitView: true, limitError: e.Message });
            return;
          }
          if (e.Message === "Unauthorized") {
            if (this.state.checkedUser) {
              this.setState({
                showError: true,
                errorMessage: getUserFriendlyErrorMessage(
                  e.UserFriendlyErrorCode,
                  e.Message
                ),
              });
            } else {
              this.setState({ checkedUser: true });
              window.localStorage.removeItem("JwtAccessToken");
              this._checkCurrentUser(this.state.nameid, this.state.envId, ()=>{this._updateList()});
            }
            return;
          }
          this.setState({
            showError: true,
            errorMessage: getUserFriendlyErrorMessage(
              e.UserFriendlyErrorCode,
              e.Message
            ),
          });
        }
      });
  };

  closePopUp = () => {
    this.setState({ showPopUp: false });
    if (this.state.lastUpdateListIDs.length) {
      this.state.fullConstituentList.map((item, index) => {
        for (let i = 0; i < this.state.lastUpdateListIDs.length; i++) {
          if (item.ListId === this.state.lastUpdateListIDs[i]) {
            item.IsPending = true;
            item.IsChecked = false;
            item.HasSyncErrors = false;
          }
        }
        return null;
      });
    }
    let sortedList = this._sortList(this.state.fullConstituentList, false);
    this.setState({
      fullConstituentList: sortedList,
    });
    this.setListPage(this.state.currentPage, sortedList);
  };
  setListPage(page, list = null) {
    list = list ? list : this.state.fullConstituentList;
    const startNumber = (page - 1) * 10;
    const subList = list.slice(startNumber, startNumber + 10);
    window.localStorage.setItem(`fullConstituentList`, JSON.stringify(list));
    window.localStorage.setItem(`subConstituentList`, JSON.stringify(subList));
    this.setState({
      subConstituentList: subList,
      pageAdder: startNumber,
      currentPage: page,
    });
  }
  render() {
    if (this.state.showLimitView && this.state.showIndicator) {
      this.setState({ showIndicator: false, blockOut: false });
    }
   /* if (this.state.redirect) {
      return <Redirect to={`/donorsearchdata`}/>;
    }*/
    return (
      <body>
        {this._showWaiting()}
        {this._showButtonOptions()}
        {this._showError()}
        {this._getInfoArea()}
        {this._getResultsArea()}
        {this._showPendingPopUp()}
        <div style={{ marginTop: !this.state.showLimitView ? 20 : 0 }}></div>
        <Footer />
      </body>
    );
  }
}

export default DonorSearchDataTile;
