import React, { Component } from "react";

import Cookies from "universal-cookie";
import "./loginresult.scss";
import "../../main.scss";
import {apiCall} from '../../utils/restclient'
import loginSuccess from '../../components/images/login_success.png'
import loginFail from '../../components/images/login_fail.png'
import moment from "moment";
class LoginResultPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OneTimeAuthCode:"",
      BlackbaudAuthCode:"",
      error:"",
      showSuccess: false,
      ErrorCode:"",
      UserFriendlyErrorCode:0
    };

    this.cookies = new Cookies();

    
  }

  
componentDidMount(){
  let search = window.location.search;
let params = new URLSearchParams(search);
this.setState({OneTimeAuthCode: params.get('state'), BlackbaudAuthCode:params.get('code'), error:params.get('error')});
}
 
  
  _getResultView() {
    if(this.state.BlackbaudAuthCode === "" && this.state.error === "")
    {
      return(<div/>)
    }
    if(this.state.BlackbaudAuthCode)
    {
      const {error, showSuccess, ...body} = this.state;
      if(this.state.showSuccess === false){
      apiCall("v1/auth/blackbaud","POST",{}, JSON.stringify(body)).then((response)=>{
        if(response.IsBatchUpdateAllowed)
        {
          window.localStorage.setItem(`IsBatchUpdateAllowed`, JSON.stringify({data:"yes",  expires:moment().add(10, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSZ")}));
        }
        if(response.IsSingleUpdateAllowed)
        {
          window.localStorage.setItem(`IsSingleUpdateAllowed`, JSON.stringify({data:"yes",  expires:moment().add(10, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSZ")}));
        }
        window.localStorage.setItem(`JwtAccessToken`, JSON.stringify({data:response.JwtAccessToken,  expires:moment().add(10, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSZ")}));

        window.opener.postMessage({JwtAccessToken:response.JwtAccessToken}, `${window.location.protocol}//${window.location.host}`);
        this.setState({ showSuccess: true});
      }).catch((e) => {

        if(e.ErrorCode === "AuthenticationFail")
        {
          window.localStorage.removeItem("userIdToken")
          this.setState({ErrorCode: e.ErrorCode, BlackbaudAuthCode:"", OneTimeAuthCode:"", error:"Authentication Failed", UserFriendlyErrorCode: parseInt(e.UserFriendlyErrorCode)})
        }
        else if(e.ErrorCode === "AccessDenied")
        {
          this.setState({ErrorCode:e.ErrorCode, BlackbaudAuthCode:"", OneTimeAuthCode:"", error:"The user's organization is not allowed to use our app", UserFriendlyErrorCode: parseInt(e.UserFriendlyErrorCode)})
        }
      })
    }
      else 
      {
       return( <div>

       <div className="center"><img src={loginSuccess} alt="Success" width="125" /></div>
        <div class="center result-text">Success</div>
        <div class="center info-text">You've successfully logged in.</div>
        <div class="center info-text">We're redirecting you to NXT</div>
        </div>)
      }
    }
    else
    {
      window.localStorage.setItem("loginError", "true")
      window.opener.postMessage({loginError:"true", UserFriendlyErrorCode: this.state.UserFriendlyErrorCode}, `${window.location.protocol}//${window.location.host}`);

        return(<div>

        <div className="center"><img src={loginFail} alt="Fail" width="125" /></div>
        <div class="center result-text">Sorry!</div>
        {this.state.ErrorCode? 
          <div class="center info-text">{this.state.error}</div>: <div class="center info-text">Access Denied</div>
        }
        </div>)
    }
    
  }
  render() {
    return (
        
    <div style={{ display:'flex', alignItems:'center', justifyContent:'center', position:'absolute', height:'100%', width:'100%',marginTop: 'auto', marginBottom:'auto', marginLeft:'auto', marginRight:'auto', top:0, bottom:0, left:0, right:0 }}>
                {this._getResultView()}
                   
        </div>
    );
  }
}

export default LoginResultPage;
