import version from "../data/version.json"
import * as env from "../env.json";

export function getVersion() {
  return version.majorNumber + '.' + version.minorNumber + '.' + version.buildNumber;
}
export function getEnv() {
    return env.name
}
export async function checkVersion(){
  let builtVersion = version;

  return new Promise(async (resolve, reject) => {
  fetch("../version.json")
        .then((response) => {
          return response.json().then((currentVersion) => {
            console.log(currentVersion)
            return currentVersion.buildNumber !== builtVersion.buildNumber
              ? resolve(currentVersion.buildNumber)
              : resolve(false);
          })
        })
       
      })
}