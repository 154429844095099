import React, { Component } from "react";

import "./paging.scss";
import right from "../../images/arrow_right.png";
import left from "../../images/arrow_left.png";
import rightDisabled from "../../images/arrow_right_gray.png";
import leftDisabled from "../../images/arrow_left_gray.png";

class Paging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      maxPage: 1,
    };
  }
  componentWillMount() {
    let { currentPage, maxPage } = this.props;
    if (currentPage == null) currentPage = 1;
    if (maxPage == null) maxPage = 1;
    this.setState({ currentPage, maxPage });
  }
  componentDidMount() {
    setTimeout(() => {
      try {
        document.getElementById("leftArrow").style.pointerEvents = "none";
        document.getElementById(
          "page_" + this.state.currentPage
        ).style.pointerEvents = "none";
        if (this.state.maxPage === 1) {
          document.getElementById("rightArrow").style.pointerEvents = "none";
        }
      } catch (e) {}
    }, 1000);
  }
  componentWillReceiveProps(nextProps) {
    let { currentPage, maxPage } = nextProps;
    if (currentPage == null) currentPage = 1;
    if (maxPage == null) maxPage = 1;
    this.setState({ currentPage, maxPage });
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { currentPage, maxPage } = nextState;
    const { currentPage: _currentPage, maxPage: _maxPage } = this.state;
    if (currentPage !== _currentPage) {
        document.getElementById(
            "page_" + _currentPage
          ).style.pointerEvents = "visible";
      this._checkArrowButtons(currentPage);
    }
    return currentPage !== _currentPage || maxPage !== _maxPage;
  }
  _getPages() {
    let pages = [];
    for (let i = 0; i < this.state.maxPage; i++) {
      pages[i] = i + 1;
    }
    return (
      <div className="number-container">
        {pages.map((number) => {
          return (
            <span
              className="number-item item-container"
              id={"page_" + number}
              style={{
                backgroundColor:
                  number === this.state.currentPage ? "#EEEEEF" : null,
              }}
              onClick={() => this._setPage(number)}
            >
              <label className="item">{number}</label>
            </span>
          );
        })}
      </div>
    );
  }
  _setPage(number) {
    document.getElementById(
      "page_" + this.state.currentPage
    ).style.pointerEvents = "visible";
    this.setState({ currentPage: number });
    this.props.updateList(number);
  }
  _checkArrowButtons(page) {
    if (page === this.state.maxPage) {
      document.getElementById("rightArrow").style.pointerEvents = "none";
    } else {
      document.getElementById("rightArrow").style.pointerEvents = "visible";
    }

    if (page === 1) {
      document.getElementById("leftArrow").style.pointerEvents = "none";
    } else {
      document.getElementById("leftArrow").style.pointerEvents = "visible";
    }

    document.getElementById("page_" + page).style.pointerEvents = "none";
  }
  _nextPage() {
    document.getElementById(
      "page_" + this.state.currentPage
    ).style.pointerEvents = "visible";
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.updateList(this.state.currentPage + 1);
  }
  _prevPage() {
    document.getElementById(
      "page_" + this.state.currentPage
    ).style.pointerEvents = "visible";
    this.setState({ currentPage: this.state.currentPage - 1 });
    this.props.updateList(this.state.currentPage - 1);
  }
  render() {
    return (
      <div className="paging-container">
        <div
          className="item-container left-arrow-container"
          id="leftArrow"
          onClick={() => this._prevPage()}
          pointer={this.state.currentPage === 1}
        >
          <img
            color={this.state.currentPage === 1 ? "#333333" : "#000000"}
            className="arrow-img"
            src={this.state.currentPage === 1 ? leftDisabled : left}
            alt="left arrow"
          />
        </div>
        {this._getPages()}
        <div
          className="item-container"
          id="rightArrow"
          disabled={this.state.currentPage === this.state.maxPage}
          onClick={() => this._nextPage()}
        >
          <img
            color={
              this.state.currentPage === this.state.maxPage
                ? "#333333"
                : "#000000"
            }
            className="arrow-img"
            src={
              this.state.currentPage === this.state.maxPage
                ? rightDisabled
                : right
            }
            alt="right arrow"
          />
        </div>
      </div>
    );
  }
}
export default Paging;
